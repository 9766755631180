<template>
    <div class="container">
        <div
            style="
                padding: 5vh;
                margin: 0 auto;
                max-width: 500px;
                text-align: center;
            "
        >
            <img
                class="logo-img"
                src="@/assets/logo-minierp.svg"
                width="90"
                height="90"
            />
        </div>
        <!-- <div style="max-width: 50vw; margin: 3vh auto; text-align: center"> -->
        <div class="heading" v-if="errorMessage">
            <h1 style="font-weight: 500; margin: 5vh">{{ errorMessage }}</h1>
        </div>
        <div class="heading" v-if="successMessage">
            <h1 style="font-weight: 500; margin: 5vh">{{ successMessage }}</h1>
        </div>
        <form
            v-if="successMessage"
            @submit.prevent="setNewPassword"
            style="display: flex; justify-content: center; align-items: center"
        >
            <DxForm
                :data-source="userInput"
                @field-data-changed="onFieldDataChanged"
                label-location="top"
                label-mode="floating"
                class="dxForm"
                :col-count="4"
            >
                <DxSimpleItem
                    data-field="newPassword"
                    :label="{ text: 'Nova lozinka' }"
                    :required="true"
                    :editor-options="passwordEditorOptions"
                    :col-span="4"
                >
                    <DxRequiredRule message="Obavezno polje." />
                    <DxStringLengthRule
                        :min="8"
                        message="Lozinka mora imati više od 8 znakova."
                    />
                </DxSimpleItem>
                <DxSimpleItem
                    data-field="newPasswordConfirm"
                    :label="{ text: 'Potvrdite lozinku' }"
                    :editor-options="passwordConfirmEditorOptions"
                    :col-span="4"
                >
                    <DxRequiredRule message="Obavezno polje." />
                    <DxCompareRule
                        :comparison-target="passwordCompare"
                        message="Lozinke nisu jednake."
                    />
                </DxSimpleItem>
                <DxEmptyItem :col-span="4" />
                <DxEmptyItem :col-span="6" />
                <DxButtonItem
                    :button-options="submitButtonOptions"
                    styling-mode="contained"
                    :col-span="2"
                />
            </DxForm>
        </form>
        <div class="copyrightContainer">
            <span>COPYRIGHT © GT-NET 2024 . ALL RIGHTS RESERVED.</span>
        </div>
    </div>
</template>

<script>
import {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxRequiredRule,
    DxEmptyItem,
} from "devextreme-vue/form";
import { DxCompareRule, DxStringLengthRule } from "devextreme-vue/validator";
import { ref, onMounted } from "vue";
import { useDomainUserStore } from "@/stores/customDomainUserStore.js";
import { useRouter } from "vue-router";
import notify from "devextreme/ui/notify";

export default {
    name: "setNewPassword",
    props: {
        error: String,
        token: String,
    },
    components: {
        DxForm,
        DxSimpleItem,
        DxButtonItem,
        DxRequiredRule,
        DxEmptyItem,
        DxCompareRule,
        DxStringLengthRule,
    },
    setup(props) {
        const domainUserStore = useDomainUserStore();
        const router = useRouter();
        const errorMessage = ref("");
        const successMessage = ref("");
        const userInput = ref({
            newPassword: "",
            newPasswordConfirm: "",
        });

        const submitButtonOptions = ref({
            text: "Postavi novu lozinku",
            useSubmitBehavior: true,
            width: "100%",
            alignment: "left",
            type: "success",
        });

        const passwordEditorInstance = ref(null);
        const passwordConfirmEditorInstance = ref(null);

        const passwordEditorOptions = {
            height: "50px",
            mode: "password",
            onInitialized: (e) => {
                passwordEditorInstance.value = e.component;
            },
        };

        const passwordConfirmEditorOptions = {
            height: "50px",
            mode: "password",
            onInitialized: (e) => {
                passwordConfirmEditorInstance.value = e.component;
            },
        };
        const onFieldDataChanged = (e) => {
            userInput.value[e.dataField] = e.value;
        };

        const passwordCompare = () => {
            return userInput.value.newPassword;
        };

        const handleError = (error) => {
            if (error === "InvalidOrExpiredToken") {
                errorMessage.value = "Token nije valjan ili je istekao.";
            } else if (error === "TokenExpired") {
                errorMessage.value = "Token je istekao.";
            } else {
                errorMessage.value = "Neočekivana pogreška.";
            }
        };

        const handleSuccess = () => {
            successMessage.value = "Postavite novu lozinku.";
        };

        const checkString = (str) => /^[A-Za-z0-9 ]*$/.test(str);

        const validateString = (e) => checkString(e.value);
        const capitalize = (string) => {
            return (
                string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
            );
        };
        const setNewPassword = async () => {
            const res = await domainUserStore.setNewPassword({
                token: props.token,
                newPassword: userInput.value.newPassword,
            });
            if (
                res.error &&
                (res.error.statusCode === "422" || res.error.statusCode === 422)
            ) {
                if (res.error.code === "PASSWORD_VALIDATION_FAILED") {
                    passwordEditorInstance.value.option("isValid", false);
                    passwordConfirmEditorInstance.value.option(
                        "isValid",
                        false
                    );
                    notify(
                        {
                            message: res.error.details.message,
                            width: 600,
                        },
                        "warning",
                        5000
                    );
                } else {
                    const regex = /Duplicate entry '(.+?)' for key '(.+?)'/;
                    const match = res.error.message.match(regex);
                    if (match) {
                        let key = match[2];
                        key = key === "username" ? "korisničko ime" : key;
                        key = capitalize(key);
                        const translatedMessage = `${key} već postoji.`;
                        notify(
                            {
                                message: translatedMessage,
                                width: 600,
                            },
                            "warning",
                            5000
                        );
                    }
                }
            } else if (res.error) {
                notify(
                    {
                        message: res.error.message,
                        width: 600,
                    },
                    "error",
                    5000
                );
            }
            if (res.status === 200) {
                const message = `Uspješno ste postavili novu lozinku. Sada se možete prijaviti.`;
                alert(message);
                router.push("/login");
            }
        };
        onMounted(() => {
            if (props.error) handleError(props.error);
            if (props.token) handleSuccess();
        });

        return {
            setNewPassword,
            errorMessage,
            successMessage,
            userInput,
            submitButtonOptions,
            passwordEditorOptions,
            passwordConfirmEditorOptions,
            passwordCompare,
            handleError,
            handleSuccess,
            validateString,
            onFieldDataChanged,
        };
    },
};
</script>
<style>
.container {
    height: 100vh;
    width: 100vw;
    margin: 0;
    background-image: url("../assets/background4.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.dxForm {
    width: 30vw;
    max-width: 30vw;
}
.copyrightContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    height: 15px;
    margin: 1vh auto;
    text-align: center;
}
@media screen and (max-width: 768px) {
    .dxForm {
        width: 90vw;
        max-width: 90vw;
    }
}
@media screen and (max-width: 1024px) {
    .dxForm {
        width: 75vw; /* Adjust this value as needed for tablet view */
        max-width: 75vw;
    }
}
.heading {
    margin: 10vh auto 0 auto;
    text-align: center;
}
</style>

<template>
    <div class="container">
        <div
            style="
                padding: 5vh;
                margin: 0 auto;
                max-width: 500px;
                text-align: center;
            "
        >
            <img
                class="logo-img"
                src="@/assets/logo-minierp.svg"
                width="90"
                height="90"
            />
        </div>
        <div class="heading">
            <h1 style="font-weight: 500; margin: 5vh">
                Registracija nove tvrtke
            </h1>
        </div>
        <form
            @submit.prevent="registerNewDomain"
            style="display: flex; justify-content: center; align-items: center"
        >
            <DxForm
                :data-source="newDomainUser"
                @field-data-changed="onFieldDataChanged"
                label-location="top"
                label-mode="floating"
                class="dxForm"
                :col-count="6"
            >
                <DxSimpleItem
                    data-field="domainName"
                    :label="{ text: 'Naziv domene' }"
                    :required="true"
                    :editor-options="domainNameEditorOptions"
                    :col-span="6"
                >
                    <DxRequiredRule message="Obavezno polje." />
                </DxSimpleItem>
                <DxSimpleItem
                    data-field="adminUsername"
                    :required="true"
                    :label="{ text: 'Ime i prezime' }"
                    :editor-options="adminUsernameEditorOptions"
                    :col-span="6"
                >
                    <DxCustomRule
                        :validation-callback="validateString"
                        message="Korisničko ime ne smije sadržavati posebne znakove."
                    />
                    <DxRequiredRule message="Obavezno polje." />
                </DxSimpleItem>
                <DxSimpleItem
                    data-field="adminEmail"
                    :required="true"
                    :label="{ text: 'Email' }"
                    :col-span="6"
                    :editor-options="adminEmailEditorOptions"
                >
                    <DxEmailRule message="Email nije ispravan." />
                    <DxRequiredRule message="Obavezno polje." />
                </DxSimpleItem>
                <DxSimpleItem
                    data-field="adminPassword"
                    :label="{ text: 'Lozinka' }"
                    :editor-options="passwordEditorOptions"
                    :col-span="6"
                >
                    <DxRequiredRule message="Obavezno polje." />
                    <DxStringLengthRule
                        :min="8"
                        message="Lozinka mora imati više od 8 znakova."
                    />
                </DxSimpleItem>
                <DxSimpleItem
                    data-field="adminPasswordConfirm"
                    :label="{ text: 'Potvrdite lozinku' }"
                    :editor-options="passwordConfirmEditorOptions"
                    :col-span="6"
                >
                    <DxRequiredRule message="Obavezno polje." />
                    <DxCompareRule
                        :comparison-target="passwordCompare"
                        message="Lozinke nisu jednake."
                    />
                </DxSimpleItem>

                <DxSimpleItem :col-span="6">
                    <div
                        style="
                            margin: 0;
                            padding: 0;
                            margin-bottom: 1vh;
                            text-align: center;
                        "
                    >
                        <span style="color: #969696; font-size: 10px"
                            >*Naziv domene, korisničko ime i email moraju biti
                            jedinstveni na sustavu.</span
                        >
                    </div>
                </DxSimpleItem>
                <DxEmptyItem :col-span="8" />
                <DxButtonItem
                    :button-options="submitButtonOptions"
                    styling-mode="contained"
                    :col-span="4"
                />
            </DxForm>
        </form>
        <div class="loginContainer">
            <span>Već ste registrirani?</span>
            <span @click="goBack" style="cursor: pointer; color: cadetblue">
                Prijavite se.
            </span>
        </div>
        <div class="loginContainer">
            <span
                >Pregledajte cjenik i odaberite uslugu koja vam najbolje
                odgovara</span
            >
            <a
                href="http://gtnet.hr/minierp"
                style="cursor: pointer; color: cadetblue; text-decoration: none"
                target="_blank"
            >
                ovdje.
            </a>
        </div>
        <div class="copyrightContainer">
            <span>COPYRIGHT © GT-NET 2024 . ALL RIGHTS RESERVED.</span>
        </div>
    </div>
</template>

<script>
import {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxEmailRule,
    DxRequiredRule,
    DxEmptyItem,
} from "devextreme-vue/form";
import {
    DxCompareRule,
    DxCustomRule,
    DxStringLengthRule,
} from "devextreme-vue/validator";
import notify from "devextreme/ui/notify";

//Stores
import { useDomainUserStore } from "@/stores/customDomainUserStore.js";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
    name: "registerDomainView",
    components: {
        DxForm,
        DxSimpleItem,
        DxButtonItem,
        DxEmailRule,
        DxRequiredRule,
        DxEmptyItem,
        DxCompareRule,
        DxCustomRule,
        DxStringLengthRule,
    },
    setup() {
        const domainUserStore = useDomainUserStore();
        const router = useRouter();
        const passwordEditorInstance = ref(null);
        const passwordConfirmEditorInstance = ref(null);
        let userInput = ref({
            domainName: "",
            adminEmail: "",
            adminPassword: "",
            adminUsername: "",
            adminPasswordConfirm: "",
        });
        const newDomainUser = ref({
            domainName: null,
            adminEmail: null,
            adminPassword: null,
            adminUsername: null,
        });
        const submitButtonOptions = ref({
            text: "Registriraj",
            useSubmitBehavior: true,
            width: "100%",
            alignment: "left",
            type: "success",
        });
        const goBack = () => {
            router.push("/login");
        };
        const checkString = (str) => {
            const pattern = /^[A-Za-z0-9 ]*$/;
            return pattern.test(str);
        };
        const validateString = (e) => {
            return checkString(e.value);
        };
        const domainNameEditorOptions = ref({
            height: "50px",
            placeholder: "npr. imetvrtke.hr",
        });
        const adminEmailEditorOptions = ref({
            height: "50px",
        });
        const passwordEditorOptions = ref({
            height: "50px",
            mode: "password",
            onInitialized: (e) => {
                passwordEditorInstance.value = e.component;
            },
        });
        const passwordConfirmEditorOptions = ref({
            height: "50px",
            mode: "password",
            onInitialized: (e) => {
                passwordConfirmEditorInstance.value = e.component;
            },
        });
        const adminUsernameEditorOptions = ref({
            height: "50px",
            placeholder: "npr. Ivan Horvat",
        });
        const passwordCompare = () => {
            return userInput.value.adminPassword;
        };
        const onFieldDataChanged = (e) => {
            userInput.value[e.dataField] = e.value;
        };
        const capitalize = (string) => {
            return (
                string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
            );
        };
        const registerNewDomain = async () => {
            const res = await domainUserStore.registerNewDomain(
                userInput.value
            );
            if (
                res.error &&
                (res.error.statusCode === "422" || res.error.statusCode === 422)
            ) {
                if (res.error.code === "PASSWORD_VALIDATION_FAILED") {
                    passwordEditorInstance.value.option("isValid", false);
                    passwordConfirmEditorInstance.value.option(
                        "isValid",
                        false
                    );
                    notify(
                        {
                            message: res.error.details.message,
                            width: 600,
                        },
                        "warning",
                        5000
                    );
                } else {
                    const regex = /Duplicate entry '(.+?)' for key '(.+?)'/;
                    const match = res.error.message.match(regex);
                    if (match) {
                        let key = match[2];
                        key = key === "username" ? "korisničko ime" : key;
                        key = capitalize(key);
                        const translatedMessage = `${key} već postoji.`;
                        notify(
                            {
                                message: translatedMessage,
                                width: 600,
                            },
                            "warning",
                            5000
                        );
                    }
                }
            } else if (res.error) {
                notify(
                    {
                        message: res.error.message,
                        width: 600,
                    },
                    "error",
                    5000
                );
            }
            if (res.status === 200) {
                const message = `Čestitamo! Vaša registracija je uspješno dovršena. Poslali smo Vam email s poveznicom za potvrdu. Slijedite upute u emailu kako biste aktivirali svoj račun.`;
                alert(message);
                router.push("/login");
            }
        };
        return {
            userInput,
            newDomainUser,
            submitButtonOptions,
            passwordEditorOptions,
            onFieldDataChanged,
            registerNewDomain,
            domainNameEditorOptions,
            adminUsernameEditorOptions,
            passwordCompare,
            passwordConfirmEditorOptions,
            validateString,
            goBack,
            adminEmailEditorOptions,
        };
    },
};
</script>

<style scoped>
.container {
    height: 100vh;
    width: 100vw;
    margin: 0;
    background-image: url("../assets/background4.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-x: auto;
}
.loginContainer {
    max-width: 500px;
    margin: 2vh auto;
    /* padding: 3vh; */
    text-align: center;
}
.copyrightContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    height: 15px;
    margin: 1vh auto;
    text-align: center;
    /* background-color: #2a2f31; */
}
.dxForm {
    width: 30vw;
    max-width: 30vw;
}

@media screen and (max-width: 768px) {
    .dxForm {
        width: 90vw;
        max-width: 90vw;
    }
    .copyrightContainer {
        position: relative;
        max-width: 500px;
        height: 15px;
        margin: 1vh auto;
        text-align: center;
    }
}
@media screen and (max-width: 1024px) {
    .dxForm {
        width: 75vw; /* Adjust this value as needed for tablet view */
        max-width: 75vw;
    }
}
.heading {
    margin: 5vh auto 0 auto;
    text-align: center;
}
</style>

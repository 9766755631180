<template>
    <!-- <div class="table-container"> -->
    <p class="table-name">Otpremnica</p>
    <DxDataGrid
        id="dispatchNoteTable"
        :data-source="dispatchNote"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        :focused-row-enabled="true"
        :row-alternation-enabled="true"
        :allow-column-resizing="true"
        @initialized="getDispatchNoteTableInstance"
        @init-new-row="onInitNewRowDispatchNote"
        @row-inserting="onRowInsertingDispatchNote"
        @row-inserted="onRowInsertedDispatchNote"
        @editing-start="onEditingStartDispatchNote"
        @row-updating="onRowUpdatingDispatchNote"
        @row-updated="onRowUpdatedDispatchNote"
        @row-removing="onRowRemovingDispatchNote"
        @focused-row-changed="onFocusedRowChangedDispatchNote"
        @edit-canceled="onEditCanceledDispatchNote"
        @selection-changed="onSelectionChangedDispatchNote"
        edit-template="dispatchNoteEditor"
    >
        <DxPaging :page-size="20" />
        <DxPager
            :visible="true"
            display-mode="full"
            :show-info="true"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
            :allowed-page-sizes="[10, 20, 30]"
        />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="dispatchNoteTableState"
        />
        <DxToolbar>
            <DxItem
                id="addButton"
                :options="dispatchNoteAddButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="dispatchNoteRefreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="generateInvoiceFromSelectionOptions"
                :disabled="selectedRowKeys.length === 0"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxEditing
            :allow-deleting="true"
            :allow-updating="true"
            :allow-adding="true"
            :use-icons="true"
            mode="popup"
        >
            <DxPopup
                :show-title="true"
                title="Informacije o otpremnici"
                :toolbar-items="dispatchNotePopupToolbarItems"
                :drag-outside-boundary="true"
                @shown="onPopupShown"
            />
            <DxForm>
                <DxItem :col-count="3" :col-span="2" item-type="group">
                    <DxItem
                        data-field="id"
                        data-type="number"
                        :visible="false"
                    />
                    <DxItem item-type="group" col-count="3">
                        <DxItem
                            data-field="number"
                            data-type="number"
                            col-span="2"
                            :editor-options="numberOptions"
                        >
                            <DxRequiredRule />
                            <DxRangeRule :min="1" :max="2147483648" />
                        </DxItem>
                        <DxItem
                            data-field="businessYearId"
                            data-type="number"
                            :editor-options="businessYearSelectBoxOptions"
                        >
                            <DxLabel :visible="false"></DxLabel
                        ></DxItem>
                    </DxItem>
                    <DxItem
                        data-field="userId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :editor-options="userSelectBoxOptions"
                    >
                        <DxRequiredRule />
                    </DxItem>
                    <DxItem
                        css-class="buttonTest"
                        editor-type="dxButton"
                        col-span="1"
                        :editor-options="addNewCustomerButtonOptions"
                    />
                    <DxItem
                        data-field="warehouseId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :editor-options="warehouseSelectBoxOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="date"
                        data-type="date"
                        :editor-options="dateSelectOptions"
                        ><DxRequiredRule
                    /></DxItem>

                    <!-- <DxItem data-field="invoiceId" data-type="number" /> -->
                    <!-- <DxItem item-type="group" :col-span="1"></DxItem> -->
                    <DxItem
                        data-field="remark"
                        data-type="string"
                        :col-span="2"
                    >
                        <DxStringLengthRule
                            :max="250"
                            message="Opis ne može biti više od 250 znakova."
                        />
                    </DxItem>
                    <DxItem
                        data-field="modifiedById"
                        data-type="number"
                        :visible="false"
                    />
                    <DxItem
                        data-field="modifiedDate"
                        data-type="datetime"
                        :visible="false"
                    />
                </DxItem>
                <DxItem item-type="group" :col-span="2">
                    <DxItem col-span="3">
                        <template #default>
                            <DxDataGrid
                                id="dispatchNoteItemTable"
                                :data-source="dispatchNoteItemData"
                                :show-column-lines="true"
                                :show-row-lines="true"
                                :show-borders="true"
                                :column-auto-width="true"
                                @initialized="getDispatchNoteItemTableInstance"
                                @editing-start="onEditingStartDispatchNoteItem"
                                @row-inserting="onRowInsertingDispatchNoteItem"
                            >
                                <DxPaging :page-size="7" />
                                <DxPager
                                    :visible="true"
                                    display-mode="full"
                                    :show-info="true"
                                    :show-navigation-buttons="true"
                                    :show-page-size-selector="true"
                                />
                                <DxToolbar>
                                    <DxItem
                                        id="addButton"
                                        :options="
                                            dispatchNoteItemAddButtonOptions
                                        "
                                        location="after"
                                        locate-in-menu="auto"
                                        widget="dxButton"
                                    />
                                </DxToolbar>
                                <DxEditing
                                    :allow-deleting="true"
                                    :allow-updating="true"
                                    :allow-adding="true"
                                    :use-icons="true"
                                    mode="popup"
                                >
                                    <DxPopup
                                        :max-height="350"
                                        :max-width="650"
                                        :show-title="true"
                                        title="Informacije o stavci"
                                        :toolbar-items="
                                            dispatchNoteItemPopupToolbarItems
                                        "
                                        :drag-outside-boundary="true"
                                    />
                                    <DxForm>
                                        <DxItem
                                            :col-count="10"
                                            :col-span="2"
                                            item-type="group"
                                        >
                                            <DxItem
                                                data-field="priceListItemId"
                                                data-type="number"
                                                editor-type="dxSelectBox"
                                                :col-span="9"
                                                :editor-options="
                                                    priceListItemSelectBoxOptions
                                                "
                                            >
                                                <DxRequiredRule />
                                            </DxItem>
                                            <DxItem
                                                :col-span="1"
                                                editor-type="dxButton"
                                                :editor-options="
                                                    addNewPriceListItemButtonOptions
                                                "
                                            />
                                        </DxItem>
                                        <DxItem
                                            :col-count="2"
                                            :col-span="2"
                                            item-type="group"
                                        >
                                            <DxItem
                                                data-field="price"
                                                data-type="number"
                                                :editor-options="priceOptions"
                                            >
                                                <DxRangeRule
                                                    :min="0"
                                                    :max="99999999999.99"
                                            /></DxItem>
                                            <DxItem
                                                data-field="qty"
                                                data-type="number"
                                                :editor-options="qtyOptions"
                                            >
                                                <DxRangeRule
                                                    :max="99999999999.99"
                                            /></DxItem>
                                            <DxItem
                                                data-field="priceTotal"
                                                data-type="number"
                                                :editor-options="
                                                    priceTotalOptions
                                                "
                                            >
                                                <DxRangeRule
                                                    :max="99999999999.99"
                                            /></DxItem>
                                            <DxItem
                                                data-field="Stanje na skladištu"
                                                data-type="number"
                                                :editor-options="
                                                    warehouseStatusOptions
                                                "
                                            />
                                        </DxItem>
                                    </DxForm>
                                </DxEditing>
                                <DxColumnFixing :enabled="true" />
                                <DxColumn
                                    data-field="priceListItemId"
                                    data-type="number"
                                    caption="Roba"
                                >
                                    <DxLookup
                                        :data-source="priceListItem"
                                        value-expr="id"
                                        displayExpr="name"
                                    />
                                </DxColumn>
                                <DxColumn
                                    width="120"
                                    data-field="price"
                                    data-type="number"
                                    caption="Nabavna cijena"
                                    format="#,##0.00"
                                />
                                <DxColumn
                                    width="120"
                                    data-field="qty"
                                    data-type="number"
                                    caption="Količina"
                                    format="#,##0.00"
                                />
                                <DxColumn
                                    width="120"
                                    data-field="priceTotal"
                                    data-type="number"
                                    caption="Ukupna cijena"
                                    format="#,##0.00"
                                />
                                <DxSummary>
                                    <DxTotalItem
                                        column="priceTotal"
                                        summary-type="sum"
                                        :value-format="priceTotalFormat"
                                        display-format="{0}"
                                    />
                                </DxSummary>
                                <DxColumn
                                    type="buttons"
                                    :auto-width="true"
                                    :fixed="true"
                                >
                                    <DxButton
                                        hint="Uredi"
                                        icon="rename"
                                        :visible="true"
                                        @click="editDispatchNoteItem"
                                    />
                                    <DxButton
                                        hint="Obriši"
                                        icon="clear"
                                        :visible="true"
                                        @click="deleteDispatchNoteItem"
                                    />
                                </DxColumn>
                            </DxDataGrid>
                        </template>
                    </DxItem>
                </DxItem>
            </DxForm>
        </DxEditing>
        <DxColumn
            :width="80"
            data-field="id"
            data-type="number"
            caption="ID"
            :visible="false"
        />
        <DxColumn
            data-field="number"
            data-type="number"
            caption="Broj"
            :allow-hiding="false"
        />
        <DxColumn data-field="businessYearId" data-type="number" caption="God.">
            <DxLookup
                :data-source="businessYear"
                value-expr="id"
                display-expr="year"
            />
        </DxColumn>
        <DxColumn
            data-field="warehouseId"
            data-type="number"
            caption="Skladište"
        >
            <DxLookup
                :data-source="warehouse"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            data-field="date"
            data-type="date"
            caption="Datum"
            format="dd/MM/yyyy"
        />
        <DxColumn data-field="userId" data-type="number" caption="Kupac">
            <DxLookup
                :data-source="dataSource"
                value-expr="id"
                :displayExpr="userName"
            />
        </DxColumn>
        <DxColumn data-field="remark" data-type="string" caption="Opis" />
        <DxColumn
            data-field="invoiceId"
            data-type="string"
            caption="Broj računa"
        >
            <!-- <DxLookup
                    :data-source="invoice"
                    value-expr="id"
                    display-expr="number"
                /> -->
        </DxColumn>
        <DxColumn
            data-field="priceTotal"
            data-type="number"
            caption="Ukupno"
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="status"
            data-type="string"
            caption="Status"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn data-field="createdById" data-type="number" caption="Kreirao">
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="createdDate"
            data-type="datetime"
            caption="Kreirano"
            format="dd/MM/yyyy HH:mm"
        />
        <DxColumn data-field="modifiedById" data-type="number" caption="Uredio">
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="modifiedDate"
            data-type="datetime"
            caption="Uređeno"
            format="dd/MM/yyyy HH:mm"
        />

        <DxColumn
            data-field="domainId"
            data-type="number"
            caption="Domena"
            :visible="false"
            :allow-hiding="false"
            :show-in-column-chooser="false"
        >
            <DxLookup
                :data-source="domain"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
        >
            <DxButton
                hint="Uredi"
                icon="rename"
                @click="editDispatchNote"
                :visible="isNotDeleted"
            />
            <DxButton
                hint="Obriši"
                icon="trash"
                :visible="isNotDeleted"
                @click="deleteDispatchNote"
            />
            <DxButton
                hint="Dupliciraj"
                icon="copy"
                @click="duplicateDispatchNote"
            />
            <DxButton
                hint="Izradi PDF"
                icon="pdffile"
                :visible="isNotDeleted"
                @click="exportDispatchNote"
            />
        </DxColumn>
        <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser>
        <DxSelection show-check-boxes-mode="always" mode="multiple" />
    </DxDataGrid>
    <AddPopup
        :show-title="true"
        title="Informacije o stavci"
        :drag-outside-boundary="true"
        :toolbar-items="addPopupToolbarItems"
        @initialized="getAddPopupInstance"
        @hiding="cancelNewPriceListItem"
    >
        <AddForm
            :form-data="formData"
            @initialized="getAddFormInstance"
            validationGroup="addPriceListItem"
        >
            <DxItem :col-count="3" :col-span="2" item-type="group">
                <DxItem data-field="id" data-type="number" :visible="false" />
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Cijenik"
                >
                    <DxItem
                        data-field="priceListId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Cijenik' }"
                        :editor-options="priceListSelectBoxOptions"
                        ><DxRequiredRule
                    /></DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Osnovne informacije"
                >
                    <DxItem
                        data-field="code"
                        data-type="string"
                        :label="{ text: 'Šifra' }"
                    >
                        <DxRequiredRule />
                    </DxItem>
                    <DxItem
                        data-field="name"
                        data-type="string"
                        :label="{ text: 'Naziv' }"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="stockNumber"
                        data-type="string"
                        :label="{ text: 'Kataloški broj' }"
                    />
                    <DxItem
                        data-field="foreignName"
                        data-type="string"
                        :label="{ text: 'Strani naziv' }"
                    />
                    <DxItem
                        data-field="ean"
                        data-type="string"
                        :label="{ text: 'EAN' }"
                    />
                    <DxItem
                        data-field="customsNumber"
                        data-type="string"
                        :label="{ text: 'Carinski broj' }"
                    />
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Tip/Dobavljač"
                >
                    <DxItem
                        data-field="measureUnit"
                        data-type="string"
                        :label="{ text: 'JMJ' }"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="vatId"
                        data-type="number"
                        :label="{ text: 'PDV' }"
                        editor-type="dxSelectBox"
                        :editor-options="vatIdOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="itemType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Roba/Usluga' }"
                        :editor-options="itemTypeOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="supplierId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Dobavljač' }"
                        :editor-options="supplierSelectBoxOptions"
                    ></DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Informacije o cijeni"
                >
                    <DxItem
                        data-field="latestProcurementPrice"
                        data-type="number"
                        :disabled="true"
                        :label="{ text: 'Zadnja nabavna' }"
                    />
                    <DxItem
                        data-field="priceCalculationType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Izračun cijene' }"
                        :editor-options="priceCalculationTypeOptions"
                    />
                    <DxItem
                        data-field="wholesalePrice"
                        data-type="number"
                        :label="{ text: 'Veleprodajna' }"
                        :editor-options="wholesalePriceOptions"
                    >
                        <DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="vatAmount"
                        data-type="number"
                        :label="{ text: 'Iznos PDV-a' }"
                        :editor-options="vatAmountOptions"
                    />
                    <DxItem
                        data-field="retailPrice"
                        data-type="number"
                        :label="{ text: 'Maloprodajna' }"
                        :editor-options="retailPriceOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="margin"
                        data-type="number"
                        :label="{ text: 'Marža' }"
                    />
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Ostale informacije"
                >
                    <DxItem
                        data-field="returnPackagingQty"
                        data-type="number"
                        :label="{ text: 'Povratna ambalaža' }"
                    />
                    <DxItem
                        data-field="orderedQty"
                        data-type="number"
                        :label="{ text: 'Naručeno' }"
                    />
                    <DxItem
                        data-field="minStock"
                        data-type="number"
                        :label="{ text: 'Min. zaliha' }"
                    />
                    <DxItem
                        data-field="maxStock"
                        data-type="number"
                        :label="{ text: 'Max. zaliha' }"
                    />
                    <DxItem
                        data-field="hidden"
                        data-type="number"
                        :editor-options="hiddenOptions"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Skriveno' }"
                    />
                </DxItem>
            </DxItem>
        </AddForm>
    </AddPopup>
    <AddPopup
        :show-title="true"
        title="Informacije o kupcu"
        :drag-outside-boundary="true"
        :toolbar-items="addCustomerPopupToolbarItems"
        @initialized="getAddCustomerPopupInstance"
        @hiding="cancelNewCustomer"
    >
        <AddForm
            :form-data="formDataCustomer"
            @initialized="getAddCustomerFormInstance"
            validationGroup="addCustomer"
        >
            <DxItem :col-count="3" :col-span="2" item-type="group">
                <DxItem :col-count="1" :col-span="3" item-type="group">
                    <DxButtonItem
                        :button-options="fetchCompanyDataButtonOptions"
                        horizontal-alignment="left"
                    />
                </DxItem>
                <DxItem data-field="id" data-type="number" :visible="false" />
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Informacije o kupcu"
                >
                    <DxItem
                        data-field="vatId"
                        data-type="string"
                        :label="{ text: 'OIB' }"
                        :editor-options="oibOptions"
                    >
                        <DxRequiredRule />
                        <DxStringLengthRule
                            :max="11"
                            :min="11"
                            message="OIB mora sadržavati 11 brojeva."
                        />
                    </DxItem>
                    <DxItem
                        data-field="name"
                        data-type="string"
                        :label="{ text: 'Ime' }"
                        ><DxRequiredRule />
                    </DxItem>
                    <DxItem
                        data-field="lastname"
                        data-type="string"
                        :label="{ text: 'Prezime' }"
                        ><DxRequiredRule />
                    </DxItem>
                    <DxItem
                        data-field="sign"
                        data-type="string"
                        :label="{ text: 'Skr. naziv' }"
                    >
                        <DxRequiredRule />
                    </DxItem>
                    <DxItem
                        data-field="companyName"
                        data-type="string"
                        :label="{ text: 'Naziv firme' }"
                        :editor-options="companyNameOptions"
                        ><DxRequiredRule v-if="userType === 'business'" />
                    </DxItem>
                    <DxItem
                        data-field="userType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Tip kupca' }"
                        :editor-options="userTypeSelectBoxOptions"
                    >
                        <DxRequiredRule
                    /></DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Adresa/Kontakt"
                >
                    <DxItem
                        data-field="postalCode"
                        data-type="string"
                        :label="{ text: 'Poštanski broj' }"
                        :editor-options="zipCodeOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="city"
                        data-type="string"
                        :label="{ text: 'Grad' }"
                        :editor-options="cityOptions"
                    >
                    </DxItem>

                    <DxItem
                        data-field="address"
                        data-type="string"
                        :label="{ text: 'Adresa' }"
                        :editor-options="streetOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="country"
                        data-type="string"
                        :label="{ text: 'Država' }"
                        :editor-options="countryOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="email"
                        data-type="string"
                        :label="{ text: 'E-mail' }"
                        :editor-options="emailOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="mobile"
                        data-type="string"
                        :label="{ text: 'Mobitel' }"
                    >
                    </DxItem>
                    <DxItem
                        data-field="tel"
                        data-type="string"
                        :label="{ text: 'Telefon' }"
                    >
                    </DxItem>
                    <DxItem
                        data-field="fax"
                        data-type="string"
                        :label="{ text: 'Fax' }"
                    >
                    </DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Ostale informacije"
                >
                    <DxItem
                        data-field="billDeliveryMethod"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Način dostave računa' }"
                        :editor-options="billDeliveryMethodSelectBoxOptions"
                    />
                    <DxItem
                        data-field="note"
                        data-type="string"
                        :label="{ text: 'Napomena' }"
                    ></DxItem>
                    <DxItem
                        data-field="status"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Status' }"
                        :editor-options="statusSelectBoxOptions"
                    />
                    <DxItem
                        data-field="newsletter"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Novine' }"
                        :editor-options="newsletterSelectBoxOptions"
                        data-type="number"
                    ></DxItem>
                </DxItem>
            </DxItem>
        </AddForm>
    </AddPopup>
    <AddPopup
        :resize-enabled="true"
        :max-width="1000"
        :drag-outside-boundary="true"
        :toolbarItems="sudregPopupToolbarItems"
        title="Učitaj podatke iz sudskog registra"
        @initialized="getSudregPopupInstance"
    >
        <DxDataGrid
            id="sudregDataTable"
            :data-source="sudregApi"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            no-data-text="Započnite pretraživanje"
            @initialized="getSudregDataTableInstance"
        >
            <DxToolbar>
                <DxItem
                    location="before"
                    locate-in-menu="auto"
                    widget="dxTextBox"
                    :options="searchBarOptions"
                />
            </DxToolbar>
            <DxPaging :page-size="20" />
            <DxColumnFixing :enabled="true" />
            <DxPager
                :allowed-page-sizes="[]"
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxColumn
                type="buttons"
                :width="70"
                :show-in-column-chooser="false"
                :fixed="true"
                caption="Učitaj"
                fixed-position="left"
            >
                <DxButton
                    hint="Učitaj"
                    icon="chevrondoubleright"
                    :visible="true"
                    @click="fetchCompanyData"
                />
            </DxColumn>
            <DxColumn data-field="mbs" data-type="string" caption="MBS" />
            <DxColumn data-field="oib" data-type="string" caption="OIB" />
            <DxColumn data-field="ime" data-type="string" caption="Naziv" />
        </DxDataGrid>
    </AddPopup>
    <!-- </div> -->
    <div class="split"></div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
    DxSelection,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import { DxPopup as AddPopup } from "devextreme-vue/popup";
import { DxForm as AddForm } from "devextreme-vue/form";
import eventBus from "../../eventBus.js";
import { useDispatchNote } from "@/composables/useDispatchNote.js";
import { useInvoice } from "@/composables/useInvoice.js";
import { usePriceListItem } from "@/composables/usePriceListItem.js";
import { useCustomer } from "@/composables/useCustomer.js";
import { useSudregApiStore } from "@/stores/customSudregApiStore";
import { DxButtonItem } from "devextreme-vue/cjs/form";
import { confirm } from "devextreme/ui/dialog";
import { ref } from "vue";

export default {
    name: "dispatchNoteTableComponent",
    components: {
        AddPopup,
        AddForm,
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxButtonItem,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
        DxSelection,
    },
    setup() {
        //table instances
        const tableInstance = ref(null);
        const itemTableInstance = ref(null);
        const addPopupInstance = ref(null);
        const addFormInstance = ref(null);
        const addCustomerPopupInstance = ref(null);
        const addCustomerFormInstance = ref(null);
        const sudregPopupInstance = ref(null);
        const sudregApiDataTableInstance = ref(null);

        //item instances
        const qtyInstance = ref(null);
        const priceInstance = ref(null);
        const numberInstance = ref(null);
        const warehouseInstance = ref(null);
        const dateSelectInstance = ref(null);
        const priceTotalInstance = ref(null);
        const priceListItemInstance = ref(null);
        const warehouseStatusInstance = ref(null);
        const vatAmountInstance = ref(null);
        const wholesalePriceInstance = ref(null);
        const retailPriceInstance = ref(null);
        const userTypeInstance = ref(null);
        const searchBarInstance = ref(null);

        //general data
        const qty = ref(1);
        const price = ref(0);
        const number = ref(null);
        const priceTotal = ref(null);
        const selectedDate = ref(null);
        const selectedRowKey = ref(null);
        const selectedRowKeys = ref([]);
        const priceListItemId = ref(null);
        const duplicateDispatchNoteData = ref(null);
        const oibInstance = ref(null);
        const companyNameInstance = ref(null);
        const zipCodeInstance = ref(null);
        const cityInstance = ref(null);
        const streetInstance = ref(null);
        const countryInstance = ref(null);
        const emailInstance = ref(null);
        const userType = ref("");
        const formData = ref({
            vatId: 1,
            hidden: 0,
            priceCalculationType: "Fiksna cijena",
        });
        const formDataCustomer = ref({
            userType: "private",
            billDeliveryMethod: "email",
            status: "active",
            newsletter: 1,
        });
        const {
            customer,
            user,
            domain,
            invoice,
            warehouse,
            businessYear,
            dispatchNote,
            dispatchNoteItem,
            domainUser,
            dispatchNoteItemData,
            priceTotalFormat,
            admin,
            businessYearId,
            columnChooserMode,
            warehouseItemStatus,
            priceListItem,
            rowsToBeDeleted,
            handleRowInsertingDispatchNote,
            handleRowInsertedDispatchNote,
            handleEditingStartDispatchNote,
            handleEditCanceledDispatchNote,
            handleRowUpdatingDispatchNote,
            handleRowRemovingDispatchNote,
            handleCancelDispatchNoteItem,
            handleSaveNewDispatchNoteItem,
            handleRowInsertingDispatchNoteItem,
            handleEditingStartDispatchNoteItem,
        } = useDispatchNote();
        const dataSource = admin.value.domainId === 1 ? user : customer;
        const { generateInvoiceFromDispatchNotes } = useInvoice();
        const {
            tax,
            vatId,
            vatValue,
            vatAmount,
            priceList,
            supplier,
            retailPrice,
            hiddenOptions,
            wholesalePrice,
            itemTypeOptions,
            updateRetailPrice,
            updateWholesalePrice,
            priceCalculationTypeOptions,
        } = usePriceListItem();
        const {
            sudregApi,
            customerTypes,
            status,
            billDeliveryMethods,
            newsletterOptions,
        } = useCustomer();
        const sudregApiStore = useSudregApiStore();

        //button item options
        const dispatchNoteAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addDispatchNote();
            },
        });
        const dispatchNoteItemAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addDispatchNoteItem();
            },
        });
        const dispatchNoteRefreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });
        const generateInvoiceFromSelectionOptions = ref({
            icon: "import",
            hint: "Napravi račun",
            onClick: () => {
                generateInvoiceFromSelectedDispatchNotes();
            },
        });
        const addNewPriceListItemButtonOptions = ref({
            icon: "add",
            hint: "Dodaj novu robu",
            onClick: () => openAddNewPriceListItemPopup(),
        });
        const fetchCompanyDataButtonOptions = ref({
            text: "Učitaj podatke iz sudskog registra",
            stylingMode: "contained",
            width: 300,
            type: "default",
            onClick: () => {
                openSudregPopup();
            },
        });
        const addNewCustomerButtonOptions = ref({
            icon: "add",
            hint: "Dodaj novog kupca",
            onClick: () => openAddNewCustomerPopup(),
        });
        const searchBarOptions = ref({
            width: 240,
            label: "Pretraživanje",
            labelMode: "floating",
            onInitialized: (e) => {
                searchBarInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                eventBus.emit("searchValue", e.value);
                sudregApiDataTableInstance.value.refresh();
            },
        });

        //Toolbar items
        const dispatchNotePopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveNewDispatchNote(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelDispatchNote(),
                },
            },
        ]);
        const dispatchNoteItemPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveNewDispatchNoteItem(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelDispatchNoteItem(),
                },
            },
        ]);
        const addPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveNewPriceListItem(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelNewPriceListItem(),
                },
            },
        ]);
        const addCustomerPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewCustomer(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelNewCustomer(),
                },
            },
        ]);
        const sudregPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => sudregPopupInstance.value.hide(),
                },
            },
        ]);

        //form item options
        const dateSelectOptions = ref({
            onInitialized: (e) => {
                dateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            },
        });
        const warehouseStatusOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                warehouseStatusInstance.value = e.component;
            },
        });
        const numberOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                numberInstance.value = e.component;
            },
            onValueChanged: () => {
                numberInstance.value.option("disabled", false);
            },
        });
        const userSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: dataSource.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi kupca",
        });
        const warehouseSelectBoxOptions = ref({
            dataSource: warehouse.value,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                warehouseInstance.value = e.component;
                warehouseInstance.value.focus();
            },
            onSelectionChanged: async (e) => {
                if (!warehouseInstance.value.option("disabled")) {
                    const loadOptions = {
                        warehouseId: e.selectedItem.id,
                        businessYearId: Number(businessYearId.value),
                    };
                    const res = await dispatchNote.value.load(loadOptions);
                    if (res.length > 0) {
                        numberInstance.value.option("value", res[0].number + 1);
                        number.value = res[0].number + 1;
                    } else {
                        numberInstance.value.option("value", 1);
                        number.value = 1;
                    }
                }
            },
        });
        const priceListItemSelectBoxOptions = ref({
            dataSource: priceListItem.value,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                priceListItemInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                const selectedWarehouseId =
                    warehouseInstance.value.option("value");
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId,
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
                const res = await priceListItem.value.byKey(e.value);
                if (res.latestProcurementPrice !== null) {
                    priceInstance.value.option(
                        "value",
                        res.latestProcurementPrice
                    );
                } else {
                    priceInstance.value.option("value", 0);
                }
                priceListItemId.value = e.value;
            },
        });
        const priceListSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: priceList.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            displayExpr: "name",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi cijenik",
        });
        const supplierSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: supplier.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            displayExpr: "companyName",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi dobavljača",
        });
        const vatAmountOptions = {
            value: vatAmount.value,
            format: "###,###,###,##0.00",
            readOnly: true,
            onInitialized: (e) => {
                vatAmountInstance.value = e.component;
            },
        };
        const vatIdOptions = {
            dataSource: tax.value,
            valueExpr: "id",
            displayExpr: "taxValue",
            placeholder: "Odaberi stopu poreza",
            onValueChanged: async (e) => {
                if (e.value !== null) {
                    vatId.value = e.value;
                    formData.value.vatId = e.value;
                    await updateWholesalePrice(
                        wholesalePrice.value,
                        wholesalePriceInstance.value,
                        retailPriceInstance.value,
                        vatAmountInstance.value
                    );
                }
            },
        };
        const wholesalePriceOptions = {
            value: wholesalePrice.value,
            format: "###,###,###,##0.00",
            // format: { style: 'currency'},
            onInitialized: (e) => {
                wholesalePriceInstance.value = e.component;
                wholesalePriceInstance.value.option(
                    "value",
                    wholesalePrice.value
                );
            },
            onValueChanged: async (e) => {
                if (e.value !== null)
                    await updateWholesalePrice(
                        e,
                        wholesalePriceInstance.value,
                        retailPriceInstance.value,
                        vatAmountInstance.value
                    );
            },
        };
        const retailPriceOptions = {
            value: retailPrice.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                retailPriceInstance.value = e.component;
                retailPriceInstance.value.option("value", retailPrice.value);
            },
            onValueChanged: async (e) => {
                if (e.value !== null)
                    await updateRetailPrice(
                        e,
                        retailPriceInstance.value,
                        wholesalePriceInstance.value,
                        vatAmountInstance.value
                    );
            },
        };
        const businessYearSelectBoxOptions = ref({
            dataSource: businessYear.value,
            valueExpr: "id",
            disabled: true,
        });
        const qtyOptions = ref({
            value: qty.value,
            onInitialized: (e) => {
                qtyInstance.value = e.component;
                qtyInstance.value.option("value", qty.value);
            },
            onValueChanged: (e) => {
                qty.value = e.value;
                qtyInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceOptions = ref({
            disabled: true,
            value: price.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                priceInstance.value = e.component;
            },
            onValueChanged: (e) => {
                price.value = e.value;
                priceInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceTotalOptions = ref({
            value: priceTotal.value,
            format: "###,###,###,##0.00",
            // readOnly: true,
            onInitialized: (e) => {
                priceTotalInstance.value = e.component;
            },
        });
        const oibOptions = ref({
            onInitialized: (e) => {
                oibInstance.value = e.component;
            },
        });
        const companyNameOptions = ref({
            onInitialized: (e) => {
                companyNameInstance.value = e.component;
            },
        });
        const zipCodeOptions = ref({
            onInitialized: (e) => {
                zipCodeInstance.value = e.component;
            },
        });
        const cityOptions = ref({
            onInitialized: (e) => {
                cityInstance.value = e.component;
            },
        });
        const streetOptions = ref({
            onInitialized: (e) => {
                streetInstance.value = e.component;
            },
        });
        const countryOptions = ref({
            onInitialized: (e) => {
                countryInstance.value = e.component;
            },
        });
        const emailOptions = ref({
            onInitialized: (e) => {
                emailInstance.value = e.component;
            },
        });
        const userTypeSelectBoxOptions = ref({
            items: customerTypes.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi tip kupca",
            onInitialized: (e) => {
                userTypeInstance.value = e.component;
            },
            onSelectionChanged: (e) => {
                if (e.selectedItem !== null) {
                    userType.value = e.selectedItem.value;
                } else {
                    userType.value = "";
                }
            },
        });
        const billDeliveryMethodSelectBoxOptions = ref({
            items: billDeliveryMethods.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi način dostave",
        });
        const statusSelectBoxOptions = ref({
            items: status.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi status kupca",
        });
        const newsletterSelectBoxOptions = ref({
            items: newsletterOptions.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi pretplatu",
        });

        //instances
        const getDispatchNoteTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getDispatchNoteItemTableInstance = (e) => {
            itemTableInstance.value = e.component;
        };
        const getAddPopupInstance = (e) => {
            addPopupInstance.value = e.component;
        };
        const getAddFormInstance = (e) => {
            addFormInstance.value = e.component;
        };
        const getAddCustomerPopupInstance = (e) => {
            addCustomerPopupInstance.value = e.component;
        };
        const getAddCustomerFormInstance = (e) => {
            addCustomerFormInstance.value = e.component;
        };
        const getSudregPopupInstance = (e) => {
            sudregPopupInstance.value = e.component;
        };
        const getSudregDataTableInstance = (e) => {
            sudregApiDataTableInstance.value = e.component;
        };
        //dispatch note table methods
        const onSelectionChangedDispatchNote = (e) => {
            selectedRowKeys.value = e.selectedRowKeys;
        };
        const onFocusedRowChangedDispatchNote = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit(
                        "newFocusedRowDispatchNote",
                        selectedRowKey.value
                    );
                }
            } else {
                eventBus.emit("newFocusedRowDispatchNote", null);
            }
        };
        const addDispatchNote = () => {
            duplicateDispatchNoteData.value = null;
            tableInstance.value.addRow();
        };
        const duplicateDispatchNote = (e) => {
            duplicateDispatchNoteData.value = e.row.data;
            tableInstance.value.addRow();
        };
        const editDispatchNote = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelDispatchNote = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteDispatchNote = async (e) => {
            let res = confirm(
                "Jeste li sigurni da želite obrisati otpremnicu?"
            );
            res.then(async (c) => {
                if (c) {
                    await handleRowRemovingDispatchNote(e);
                    tableInstance.value.refresh();
                    // tableInstance.value.deleteRow(e.row.rowIndex);
                }
            });
        };
        const onInitNewRowDispatchNote = (e) => {
            const now = new Date().toISOString();
            if (duplicateDispatchNoteData.value !== null) {
                e.data = duplicateDispatchNoteData.value;
                priceListItemId.value = null;
                setTimeout(() => {
                    dateSelectInstance.value.option("value", now);
                }, 100);
            } else {
                e.data = {
                    businessYearId: Number(businessYearId.value),
                };
                priceListItemId.value = null;
                tableInstance.value.option("focusedRowIndex", -1);
                dispatchNoteItemData.value = [];
                setTimeout(() => {
                    dateSelectInstance.value.option("value", now);
                }, 100);
            }
        };
        const saveNewDispatchNote = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertingDispatchNote = (e) => {
            handleRowInsertingDispatchNote(e, number.value, selectedDate.value);
        };
        const onRowInsertedDispatchNote = async (e) => {
            await handleRowInsertedDispatchNote(e);
            qty.value = 1;
            tableInstance.value.refresh();
        };
        const onEditingStartDispatchNote = async (e) => {
            handleEditingStartDispatchNote(e);
            const now = new Date();
            setTimeout(() => {
                tableInstance.value.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now.toISOString()
                );
                warehouseInstance.value.option("disabled", true);
                dateSelectInstance.value.option("value", e.data.date);
            }, 100);
            priceListItemId.value = null;
        };
        const onEditCanceledDispatchNote = () => {
            handleEditCanceledDispatchNote();
            tableInstance.value.refresh();
        };
        const onRowUpdatingDispatchNote = async (e) => {
            if (selectedDate.value !== e.oldData.date) {
                e.newData.date = selectedDate.value;
            }
            await handleRowUpdatingDispatchNote(e);
            qty.value = 1;
        };
        const onRowUpdatedDispatchNote = () => {
            eventBus.emit("newDispatchNoteItemAdded");
            tableInstance.value.refresh();
        };
        //TODO: Obrisati id iz računa?
        const onRowRemovingDispatchNote = async (e) => {
            await handleRowRemovingDispatchNote(e);
        };
        const exportDispatchNote = () => {
            let res = confirm("Ispisati otpremnicu u pdf formatu?");
            res.then((e) => {
                if (e)
                    eventBus.emit("exportDispatchNote", selectedRowKey.value);
            });
        };

        //dispatch note item methods
        const addDispatchNoteItem = () => {
            itemTableInstance.value.addRow();
        };
        const editDispatchNoteItem = (e) => {
            itemTableInstance.value.editRow(e.row.rowIndex);
        };
        const deleteDispatchNoteItem = (e) => {
            dispatchNoteItemData.value.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                rowsToBeDeleted.value.push(e.row.data);
            }
            itemTableInstance.value.refresh();
        };
        const cancelDispatchNoteItem = () => {
            handleCancelDispatchNoteItem();
            itemTableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const saveNewDispatchNoteItem = () => {
            handleSaveNewDispatchNoteItem(
                qty.value,
                price.value,
                priceTotal.value
            );

            itemTableInstance.value.saveEditData();
            qty.value = 1;
        };
        const onRowInsertingDispatchNoteItem = (e) => {
            handleRowInsertingDispatchNoteItem(
                e,
                qty.value,
                price.value,
                priceTotal.value,
                priceListItemId.value
            );
            qty.value = 1;
        };
        const onEditingStartDispatchNoteItem = (e) => {
            qty.value = e.data.qty;
            handleEditingStartDispatchNoteItem(e);
            price.value = e.data.price;
            priceListItemId.value = null;
            setTimeout(() => {
                priceListItemInstance.value.option("disabled", true);
            }, 100);
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const generateInvoiceFromSelectedDispatchNotes = async () => {
            try {
                const res = await generateInvoiceFromDispatchNotes(
                    selectedRowKeys.value,
                    admin.value.id,
                    businessYearId.value,
                    admin.value.domainId
                );
                if (res) {
                    tableInstance.value.clearSelection();
                    tableInstance.value.refresh();
                    const message = "Račun napravljen.";
                    notify(
                        {
                            message,
                            width: 450,
                        },
                        "success"
                    );
                }
            } catch (error) {
                console.error(error);
                notify(
                    {
                        message: error.message,
                        width: 450,
                    },
                    "warning"
                );
            }
        };
        const openAddNewPriceListItemPopup = () => {
            addPopupInstance.value.show();
        };
        const saveNewPriceListItem = async () => {
            const now = new Date().toISOString();
            const isValid = addFormInstance.value.validate("addPriceListItem");
            if (isValid.isValid) {
                formData.value.vatId = Number(formData.value.vatId);
                formData.value = {
                    ...formData.value,
                    vat: vatValue.value * 100,
                    createdById: admin.value.id,
                    modifiedById: admin.value.id,
                    createdDate: now,
                    modifiedDate: now,
                    domainId: admin.value.domainId,
                };
                try {
                    await priceListItem.value.insert(formData.value);
                    addPopupInstance.value.hide();
                    formData.value = {
                        vatId: 1,
                        hidden: 0,
                        priceCalculationType: "Fiksna cijena",
                    };
                    wholesalePrice.value = 0;
                    retailPrice.value = 0;
                    vatId.value = 1;
                    vatValue.value = 0.25;
                    vatAmount.value = 0;
                } catch (error) {
                    console.error("Error inserting data:", error);
                    const message = error.message;
                    notify(
                        { message, width: 450, closeOnClick: true },
                        "error",
                        10000
                    );
                    return null;
                }
            }
        };
        const cancelNewPriceListItem = () => {
            formData.value = {
                vatId: 1,
                hidden: 0,
                priceCalculationType: "Fiksna cijena",
            };

            wholesalePrice.value = 0;
            retailPrice.value = 0;
            vatId.value = 1;
            vatValue.value = 0.25;
            vatAmount.value = 0;
            addPopupInstance.value.hide();
        };
        const openAddNewCustomerPopup = () => {
            addCustomerPopupInstance.value.show();
        };
        const saveNewCustomer = async () => {
            const now = new Date().toISOString();
            const isValid =
                addCustomerFormInstance.value.validate("addCustomer");
            if (isValid.isValid) {
                if (admin.value.domainId !== 1) {
                    formDataCustomer.value = {
                        ...formDataCustomer.value,
                        dateCreated: now,
                        domainId: admin.value.domainId,
                    };
                } else {
                    formDataCustomer.value = {
                        ...formDataCustomer.value,
                        dateCreated: now,
                    };
                }
                try {
                    await dataSource.value.insert(formDataCustomer.value);
                    addCustomerPopupInstance.value.hide();
                    formDataCustomer.value = {
                        userType: "private",
                        billDeliveryMethod: "email",
                        status: "active",
                        newsletter: 1,
                    };
                } catch (error) {
                    console.error("Error inserting data:", error);
                    const message = error.message;
                    notify(
                        { message, width: 450, closeOnClick: true },
                        "error",
                        10000
                    );
                    return null;
                }
            }
        };
        const cancelNewCustomer = () => {
            formDataCustomer.value = {
                userType: "private",
                billDeliveryMethod: "email",
                status: "active",
                newsletter: 1,
            };
            addCustomerPopupInstance.value.hide();
        };
        const openSudregPopup = () => {
            sudregPopupInstance.value.show();
        };
        const fetchCompanyData = async (e) => {
            const res = await sudregApiStore.fetchCompanyData({
                tip_identifikatora: "oib",
                identifikator: e.row.data.oib.toString(),
            });
            oibInstance.value.option(
                "value",
                res.potpuni_oib ? res.potpuni_oib : res.oib
            );
            companyNameInstance.value.option("value", res.tvrtka.ime);
            cityInstance.value.option(
                "value",
                res.sjediste.naziv_naselja ? res.sjediste.naziv_naselja : ""
            );
            streetInstance.value.option(
                "value",
                res.sjediste.ulica && res.sjediste.kucni_broj
                    ? res.sjediste.ulica + " " + res.sjediste.kucni_broj
                    : ""
            );
            countryInstance.value.option("value", "Hrvatska");
            emailInstance.value.option(
                "value",
                res.email_adrese ? res.email_adrese[0].adresa : ""
            );
            userTypeInstance.value.option("value", "business");
            sudregPopupInstance.value.hide();
            eventBus.emit("searchValue", "");
            searchBarInstance.value.option("value", "");
        };
        //General methods
        const updateTotalValue = () => {
            priceTotal.value = (
                Number(qty.value) * Number(price.value)
            ).toFixed(2);
            priceTotalInstance.value.option("value", Number(priceTotal.value));
        };
        const onPopupShown = () => {
            warehouseInstance.value.focus();
        };
        const userName = (item) => {
            return item.userType === "business"
                ? `${item.id} | ${item.companyName}, ${item.name} ${item.lastname}`
                : item.name || item.lastname
                ? `${item.id} | ${item.name} ${item.lastname}`
                : `${item.id} | ${item.sign}`;
        };
        const isDeleted = (e) => {
            return e.row.data.status === "deleted" ? true : false;
        };
        const isNotDeleted = (e) => {
            return e.row.data.status === "active" ? true : false;
        };

        //subscribe to events
        eventBus.on("dispatchNoteItems", (data) => {
            dispatchNoteItemData.value = data;
        });

        return {
            vatIdOptions,
            isDeleted,
            isNotDeleted,
            duplicateDispatchNote,
            dataSource,
            domain,
            invoice,
            warehouse,
            businessYear,
            dispatchNote,
            dispatchNoteItem,
            priceListItem,
            domainUser,
            dispatchNoteItemData,
            priceTotalFormat,
            // adminId,
            // domainId,
            businessYearId,
            columnChooserMode,
            dispatchNoteAddButtonOptions,
            dispatchNoteItemAddButtonOptions,
            dispatchNoteRefreshButtonOptions,
            columnChooserButtonOptions,
            generateInvoiceFromSelectionOptions,
            dispatchNotePopupToolbarItems,
            dispatchNoteItemPopupToolbarItems,
            dateSelectOptions,
            warehouseStatusOptions,
            numberOptions,
            userSelectBoxOptions,
            warehouseSelectBoxOptions,
            priceListItemSelectBoxOptions,
            businessYearSelectBoxOptions,
            qtyOptions,
            selectedRowKeys,
            priceOptions,
            priceTotalOptions,
            getDispatchNoteTableInstance,
            getDispatchNoteItemTableInstance,
            onSelectionChangedDispatchNote,
            onFocusedRowChangedDispatchNote,
            editDispatchNote,
            deleteDispatchNote,
            onInitNewRowDispatchNote,
            saveNewDispatchNote,
            onRowInsertingDispatchNote,
            onRowInsertedDispatchNote,
            onEditingStartDispatchNote,
            onEditCanceledDispatchNote,
            onRowUpdatingDispatchNote,
            onRowUpdatedDispatchNote,
            onRowRemovingDispatchNote,
            addDispatchNoteItem,
            editDispatchNoteItem,
            deleteDispatchNoteItem,
            cancelDispatchNoteItem,
            saveNewDispatchNoteItem,
            onRowInsertingDispatchNoteItem,
            onEditingStartDispatchNoteItem,
            refreshData,
            openColumnChooser,
            generateInvoiceFromSelectedDispatchNotes,
            updateTotalValue,
            onPopupShown,
            userName,
            exportDispatchNote,
            formData,
            hiddenOptions,
            itemTypeOptions,
            priceCalculationTypeOptions,
            addPopupToolbarItems,
            priceListSelectBoxOptions,
            supplierSelectBoxOptions,
            vatAmountOptions,
            wholesalePriceOptions,
            retailPriceOptions,
            getAddPopupInstance,
            getAddFormInstance,
            cancelNewPriceListItem,
            addNewPriceListItemButtonOptions,
            formDataCustomer,
            fetchCompanyDataButtonOptions,
            addCustomerPopupToolbarItems,
            oibOptions,
            companyNameOptions,
            zipCodeOptions,
            cityOptions,
            streetOptions,
            countryOptions,
            emailOptions,
            userTypeSelectBoxOptions,
            billDeliveryMethodSelectBoxOptions,
            statusSelectBoxOptions,
            newsletterSelectBoxOptions,
            getAddCustomerPopupInstance,
            getAddCustomerFormInstance,
            addNewCustomerButtonOptions,
            cancelNewCustomer,
            userType,
            sudregApi,
            searchBarOptions,
            sudregPopupToolbarItems,
            getSudregPopupInstance,
            getSudregDataTableInstance,
            fetchCompanyData,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#dispatchNoteTable {
    max-width: 100%;
}
#dispatchNoteItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin: 1vh;
}
.split {
    border: 0.2px solid #1c1c1c;
    margin: 0.5vh;
    /* width: 84.5vw; */
    max-width: 100vw;
}
</style>

import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";

export const warehouseItemStatusPriceAverage = new CustomStore({
    key: "id",
    update: async (values) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.PRICE_AVERAGE}`,
                "PATCH",
                {
                    values: values,
                }
            );
            return res;
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
});

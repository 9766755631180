import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";

export const rolePrivileges = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            let filterCondition;
            if (loadOptions.roleId) {
                filterCondition = {
                    where: {
                        roleId: loadOptions.roleId,
                    },
                };
            }
            const res = await sendRequest(
                `${config.API_URL}/${
                    config.PATHS.ROLE_PRIVILEGES
                }?filter=${JSON.stringify(filterCondition)}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.ROLE_PRIVILEGES}`,
                "POST",
                {
                    values: values,
                }
            );
            return res;
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.ROLE_PRIVILEGES}/${id}`,
                "PATCH",
                {
                    values: values,
                }
            );
            return res;
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
});

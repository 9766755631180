<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid"
        id="dispatchNoteItemTable"
        :data-source="dispatchNoteItem"
        :repaint-changes-only="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        @initialized="getDispatchNoteItemTableInstance"
    >
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="dispatchNoteItemTableState"
        />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPager
            :visible="true"
            display-mode="full"
            :show-info="true"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxColumnFixing :enabled="true" />
        <DxColumn data-field="id" data-type="number" :visible="false" />
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Šifra"
            name="codeColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="code"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Naziv"
            name="nameColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            :allow-exporting="false"
            width="120"
            data-field="price"
            data-type="number"
            caption="Cijena"
            format="###,###,###,##0.00"
        />
        <DxColumn
            width="120"
            data-field="qty"
            data-type="number"
            caption="Kol."
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="JMJ"
            name="measureUnitColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="measureUnit"
            />
        </DxColumn>
        <DxColumn
            :allow-exporting="false"
            width="120"
            data-field="priceTotal"
            data-type="number"
            format="###,###,###,##0.00"
            caption="Ukupno"
        />
    </DxDataGrid>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
} from "devextreme-vue/data-grid";

import { useDispatchNoteItem } from "@/composables/useDispatchNoteItem.js";
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted } from "vue";

export default {
    name: "dispatchNoteItemTableComponent",
    components: {
        DxPager,
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxLookup,
        DxStateStoring,
        // DxSummary,
        // DxTotalItem,
    },
    setup() {
        const {
            priceListItem,
            dispatchNoteItem,
            priceTotalFormat,
            getDispatchNoteByKey,
            getUserByKey,
            getBusinessYearByKey,
            handleDispatchNoteExporting,
            getDomainData,
        } = useDispatchNoteItem();
        onMounted(async () => {
            await getDomainData();
        });
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);

        const getDispatchNoteItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        eventBus.on("exportDispatchNote", (dispatchNoteId) => {
            handleDispatchNoteExporting(tableInstance.value, dispatchNoteId);
        });
        eventBus.on("newDispatchNoteItemAdded", () => {
            tableInstance.value.refresh();
        });
        eventBus.on("newFocusedRowDispatchNote", async (data) => {
            if (data !== null) {
                focusedId.value = true;
                await getDispatchNoteByKey(data);
                await getUserByKey();
                await getBusinessYearByKey();
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        return {
            priceListItem,
            dispatchNoteItem,
            priceTotalFormat,
            getDispatchNoteItemTableInstance,
            shouldShowTheDataGrid,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#dispatchNoteItemTable {
    width: 100%;
}
</style>

<template>
    <div class="container">
        <div
            style="
                padding: 5vh;
                margin: 0 auto;
                max-width: 500px;
                text-align: center;
            "
        >
            <img
                class="logo-img"
                src="@/assets/logo-minierp.svg"
                width="90"
                height="90"
            />
        </div>
        <div class="heading">
            <h1 style="font-weight: 500">Zaboravljena lozinka</h1>
        </div>
        <form
            @submit.prevent="resetPassword"
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
            "
        >
            <DxForm
                :data-source="userInput"
                @field-data-changed="onFieldDataChanged"
                label-location="top"
                label-mode="floating"
                class="dxForm"
                :col-count="6"
            >
                <DxSimpleItem
                    data-field="email"
                    :label="{ text: 'Email' }"
                    :editor-options="emailEditorOptions"
                    :col-span="6"
                >
                    <DxRequiredRule message="Obavezno polje." />
                    <DxEmailRule message="Email nije ispravan." />
                </DxSimpleItem>
                <DxSimpleItem
                    data-field="emailConfirm"
                    :label="{ text: 'Potvrdite email' }"
                    :editor-options="emailConfirmEditorOptions"
                    :col-span="6"
                >
                    <DxRequiredRule message="Obavezno polje." />
                    <DxEmailRule message="Email nije ispravan." />
                    <DxCompareRule
                        :comparison-target="emailCompare"
                        message="Email-ovi nisu jednaki."
                    />
                </DxSimpleItem>
                <DxEmptyItem :col-span="6" />
                <DxEmptyItem :col-span="8" />
                <DxButtonItem
                    :button-options="submitButtonOptions"
                    :col-span="4"
                />
            </DxForm>
        </form>
        <div class="copyrightContainer">
            <span>COPYRIGHT © GT-NET 2024 . ALL RIGHTS RESERVED.</span>
        </div>
    </div>
</template>

<script>
//DevExpress
import {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxEmailRule,
    DxRequiredRule,
    DxEmptyItem,
} from "devextreme-vue/form";
import { DxCompareRule } from "devextreme-vue/validator";
import { useDomainUserStore } from "@/stores/customDomainUserStore.js";
import { useRouter } from "vue-router";
import notify from "devextreme/ui/notify";

import { ref } from "vue";

export default {
    name: "forgottenPasswordView",
    components: {
        DxForm,
        DxSimpleItem,
        DxButtonItem,
        DxEmailRule,
        DxRequiredRule,
        DxEmptyItem,
        DxCompareRule,
    },
    setup() {
        const domainUserStore = useDomainUserStore();
        const router = useRouter();
        let userInput = ref({
            email: "",
            emailConfirm: "",
        });

        const submitButtonOptions = ref({
            text: "Pošalji email",
            useSubmitBehavior: true,
            stylingMode: "contained",
            type: "success",
            width: "100%",
            alignment: "center",
        });
        const emailEditorOptions = ref({
            height: "50px",
        });
        const emailConfirmEditorOptions = ref({
            height: "50px",
        });
        const onFieldDataChanged = (e) => {
            userInput.value[e.dataField] = e.value;
        };
        const emailCompare = () => {
            return userInput.value.email;
        };
        const resetPassword = async () => {
            const res = await domainUserStore.forgotPassword({
                email: userInput.value.email,
            });
            if (res.error) {
                notify(
                    {
                        message: res.error.message,
                        width: 600,
                    },
                    "error",
                    5000
                );
            }
            if (res.status === 200) {
                const message = `Ako email postoji, link za aktivaciju će biti poslan.`;
                alert(message);
                router.push("/login");
            }
        };
        return {
            resetPassword,
            userInput,
            submitButtonOptions,
            emailConfirmEditorOptions,
            onFieldDataChanged,
            emailEditorOptions,
            emailCompare,
        };
    },
};
</script>
<style scoped>
.container {
    height: 100vh;
    width: 100vw;
    margin: 0;
    background-image: url("../assets/background4.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.dxForm {
    width: 30vw;
    max-width: 30vw;
}

@media screen and (max-width: 768px) {
    .dxForm {
        width: 90vw;
        max-width: 90vw;
    }
}
@media screen and (max-width: 1024px) {
    .dxForm {
        width: 75vw; /* Adjust this value as needed for tablet view */
        max-width: 75vw;
    }
}
.heading {
    margin: 10vh auto 0 auto;
    text-align: center;
}
.copyrightContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    height: 15px;
    margin: 1vh auto;
    text-align: center;
}
</style>

<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid"
        id="warehouseItemStatusTable"
        :data-source="warehouseItemStatus"
        :repaint-changes-only="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        @initialized="getWarehouseItemStatusTableInstance"
        @exporting="onExporting"
    >
        <DxToolbar>
            <DxItem name="exportButton" />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxExport :enabled="true" :formats="['pdf', 'xls']" />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="warehouseItemStatusTableState"
        />
        <DxScrolling column-rendering-mode="virtual" />
        <DxSearchPanel :visible="true" />
        <DxPager
            :visible="true"
            display-mode="full"
            :show-info="true"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxColumnFixing :enabled="true" />
        <DxHeaderFilter :visible="true" />
        <DxColumn data-field="id" data-type="number" :visible="false" />
        <DxColumn
            caption="Šifra"
            data-field="priceListItemId"
            data-type="number"
            name="codeColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="code"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Naziv"
            name="nameColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            width="120"
            data-field="priceAverage"
            data-type="number"
            caption="Prosj. cijena"
            format="###,###,###,##0.00"
        />
        <DxColumn
            width="120"
            data-field="priceLatest"
            data-type="number"
            caption="Zadnja n. cijena"
            format="###,###,###,##0.00"
        />
        <DxColumn
            width="120"
            data-field="qty"
            data-type="number"
            caption="Kol."
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="JMJ"
            name="jmjColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="measureUnit"
            />
        </DxColumn>
        <DxColumn
            width="120"
            caption="Ukupno"
            :calculate-cell-value="calculateTotal"
            data-type="number"
            format="###,###,###,##0.00"
        />
        <DxSummary>
            <DxTotalItem
                column="Ukupno"
                summary-type="sum"
                :value-format="priceTotalFormat"
                display-format="{0}"
            />
        </DxSummary>
    </DxDataGrid>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxItem,
    DxPager,
    DxExport,
    DxColumn,
    DxLookup,
    DxSummary,
    DxToolbar,
    DxDataGrid,
    DxScrolling,
    DxTotalItem,
    DxHeaderFilter,
    DxColumnFixing,
    DxSearchPanel,
    DxStateStoring,
} from "devextreme-vue/data-grid";

//Composables
import { useWarehouseItemStatus } from "@/composables/useWarehouseItemStatus.js";

//Utils
import { ref, watch, onMounted } from "vue";
import eventBus from "../../eventBus.js";

export default {
    name: "warehouseItemStatusTableComponent",
    components: {
        DxItem,
        DxToolbar,
        DxPager,
        DxExport,
        DxColumn,
        DxLookup,
        DxSearchPanel,
        DxSummary,
        DxDataGrid,
        DxScrolling,
        DxTotalItem,
        DxHeaderFilter,
        DxColumnFixing,
        DxStateStoring,
    },
    setup() {
        const {
            warehouseData,
            priceListItem,
            warehouseItemStatus,
            priceTotalFormat,
            fetchWarehouseDataByKey,
            fetchBusinessYearDataByKey,
            handleWarehouseItemStatusExporting,
            getDomainData,
        } = useWarehouseItemStatus();
        const tableInstance = ref(null);
        const shouldShowTheDataGrid = ref(true);
        const focusedId = ref(false);
        onMounted(async () => {
            await getDomainData();
        });

        //Instances
        const getWarehouseItemStatusTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };

        //Warehouse item status table methods
        const onExporting = (e) => {
            handleWarehouseItemStatusExporting(e, tableInstance.value);
        };

        //general methods
        const calculateTotal = (rowData) => {
            return rowData.priceLatest * rowData.qty;
        };

        //Events
        eventBus.on("newFocusedRowWarehouse", async (data) => {
            if (data && data !== null) {
                await fetchWarehouseDataByKey(data);
                await fetchBusinessYearDataByKey(
                    warehouseData.value.businessYearId
                );
                focusedId.value = true;
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });
        eventBus.on("warehouseCalculationSuccess", () => {
            tableInstance.value.refresh();
        });

        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );

        return {
            onExporting,
            priceListItem,
            calculateTotal,
            priceTotalFormat,
            warehouseItemStatus,
            shouldShowTheDataGrid,
            getWarehouseItemStatusTableInstance,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#warehouseItemStatusTable {
    width: 100%;
}
.split {
    border: 0.2px solid #1c1c1c;
    margin: 0.5vh;
    /* width: 84.5vw; */
    max-width: 100vw;
}
</style>

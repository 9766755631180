import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore";
import { priceList } from "@/stores/priceListStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { supplier } from "@/stores/supplierStore";
import { tax } from "@/stores/taxStore";
import DataSource from "devextreme/data/data_source";
import jsPDF from "jspdf";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { poppins } from "@/poppinsbase64";
import { divWrapper } from "@/utils/divWrapper";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
export function usePriceListItem() {
    const domainUserStore = useDomainUserStore();
    const itemTypes = [{ value: "Roba" }, { value: "Usluga" }];
    const priceCalculationTypes = [{ value: "Fiksna cijena" }];
    const hiddenTypes = [
        { value: 0, name: "Ne" },
        { value: 1, name: "Da" },
    ];
    const state = reactive({
        supplier,
        tax,
        domainUser,
        priceList,
        priceListItem,
        vatId: 1,
        vatValue: 0.25,
        vatAmount: 0,
        retailPrice: 0,
        priceListData: null,
        wholesalePrice: 0,
        preventRecursiveUpdate: false,
        columnChooserMode: "select",
        admin: domainUserStore.getDomainUser,
        documentHeader: null,
        documentFooter: null,
        domainName: null,
        supplierSelectBoxOptions: {
            dataSource: new DataSource({
                store: supplier,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi dobavljača",
        },
        itemTypeOptions: {
            items: itemTypes,
            displayExpr: "value",
            valueExpr: "value",
            placeholder: "Izaberi tip stavke",
        },
        priceCalculationTypeOptions: {
            items: priceCalculationTypes,
            displayExpr: "value",
            valueExpr: "value",
            placeholder: "Izaberi tip cijene",
        },
        hiddenOptions: {
            dataSource: hiddenTypes,
            displayExpr: "name",
            valueExpr: "value",
            placeholder: "Odaberi",
        },
    });
    async function getDomainData() {
        const domainData = await domain.byKey(state.admin.domainId);
        state.domainName = domainData.name;
        if (
            domainData.documentSections &&
            domainData.documentSections !== null
        ) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    async function updateRetailPrice(e, retail, wholesale, vatAmount) {
        if (!state.preventRecursiveUpdate) {
            const res = await tax.byKey(state.vatId);
            state.vatValue = res.taxValue / 100;
            state.preventRecursiveUpdate = true;
            state.retailPrice = e.value;
            retail.option("value", state.retailPrice);
            state.wholesalePrice = state.retailPrice * (1 - state.vatValue);
            wholesale.option("value", state.wholesalePrice);
            state.vatAmount = state.retailPrice - state.wholesalePrice;
            vatAmount.option("value", state.vatAmount);
            state.preventRecursiveUpdate = false;
        }
    }
    async function updateWholesalePrice(e, wholesale, retail, vatAmout) {
        if (!state.preventRecursiveUpdate) {
            const res = await tax.byKey(state.vatId);
            state.vatValue = res.taxValue / 100;
            state.preventRecursiveUpdate = true;
            state.wholesalePrice = e.value ? e.value : e;
            wholesale.option("value", state.wholesalePrice);
            state.retailPrice = state.wholesalePrice * (1 + state.vatValue);
            retail.option("value", state.retailPrice);
            state.vatAmount = state.retailPrice - state.wholesalePrice;
            vatAmout.option("value", state.vatAmount);
            state.preventRecursiveUpdate = false;
        }
    }
    function handleNewRowInitPriceListItem(e, focusedRowId) {
        e.data = {
            hidden: 0,
            vatId: 1,
            priceListId: focusedRowId,
            priceCalculationType: "Fiksna cijena",
        };
        state.wholesalePrice = 0;
        state.retailPrice = 0;
        state.vatId = 1;
        state.vatValue = 0.25;
        state.vatAmount = 0;
    }
    function handleRowInsertingPriceListItem(e) {
        const now = new Date();
        e.data = {
            ...e.data,
            vat: state.vatValue * 100,
            vatId: state.vatId,
            wholesalePrice: state.wholesalePrice,
            retailPrice: state.retailPrice,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: state.admin.domainId,
        };
    }
    function handleEditingStartPriceListItem(e, table) {
        state.wholesalePrice = e.data.wholesalePrice;
        state.retailPrice = e.data.retailPrice;
        const now = new Date();
        setTimeout(() => {
            table.cellValue(
                e.component.getRowIndexByKey(e.key),
                "modifiedDate",
                now.toISOString()
            );
        }, 100);
    }
    function handleRowUpdatingPriceListItem(e) {
        const now = new Date();
        e.newData = {
            ...e.newData,
            modifiedById: state.admin.id,
            modifiedDate: now.toISOString(),
        };
        if (state.retailPrice !== null) {
            e.newData.retailPrice = state.retailPrice;
        }
        if (state.wholesalePrice !== null) {
            e.newData.wholesalePrice = state.wholesalePrice;
        }
        if (state.vatId !== null) {
            e.newData.vatId = state.vatId;
        }
        if (state.vatValue !== null) {
            e.newData.vat = state.vatValue * 100;
        }
    }
    function handlePriceListExporting(e) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: e.component,
            topLeft: { x: 1, y: 38 },
            repeatHeaders: true,
            columnWidths: [15, 118, 20, 27],
            onRowExporting: (e) => {
                e.rowHeight = 6;
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 7;
                }
            },
        }).then(() => {
            const now = new Date();
            const vrijeme = "Vrijeme izrade: " + now.toLocaleString("hr-HR");
            const fileData = `Cijenik: ${state.priceListData.name}`;
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();
            doc.setFontSize(12);
            doc.setPage(1);
            doc.setFontSize(8);
            doc.text(vrijeme, pageWidth - 15, 45, { align: "right" });
            doc.setFontSize(13);
            doc.text(fileData, 15, 45, { align: "left" });
            doc.setFontSize(8);
            doc.line(15, 47, pageWidth - 15, 47);
            const documentName = `Cijenik_${state.priceListData.name}.pdf`;
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 800,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            doc.save(documentName);
                        },
                    });
                },
            });
        });
    }
    async function getPriceListDataByKey(id) {
        if (id) {
            state.priceListData = await priceList.byKey(id);
        }
    }

    return {
        ...toRefs(state),
        updateRetailPrice,
        updateWholesalePrice,
        handleNewRowInitPriceListItem,
        handleRowInsertingPriceListItem,
        handleEditingStartPriceListItem,
        handleRowUpdatingPriceListItem,
        handlePriceListExporting,
        getPriceListDataByKey,
        getDomainData,
    };
}

import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { initialState } from "@/stores/initialStateStore";
import { priceList } from "@/stores/priceListStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { initialStateItem } from "@/stores/initialStateItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import {
    updateWarehouseItemStatusOnNewInitialState,
    updateWarehouseItemStatusOnUpdatedInitialState,
    updateWarehouseItemStatusOnRemovedInitialState,
} from "@/utils/updateWarehouseItemStatus";
import { useDomainUserStore } from "@/stores/customDomainUserStore";

export function useInitialState() {
    const domainUserStore = useDomainUserStore();
    const state = reactive({
        domain,
        domainUser,
        warehouse,
        businessYear,
        initialState,
        priceList,
        initialStateItem,
        priceListItem,
        warehouseItemStatus,
        editingInitialState: null,
        editingInitialStateItem: null,
        rowsToBeDeleted: [],
        initialStateItemTableData: [],
        editingInitialStateItemOldData: [],
        newEditingInitialStateItemWarehouseItemStatus: [],
        columnChooserMode: "select",
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2,
        },
        admin: domainUserStore.getDomainUser,
        businessYearId: localStorage.getItem("businessYearId"),
    });

    function handleRowInsertingInitialState(e, number, date) {
        const now = new Date();
        delete e.data.id;
        e.data = {
            ...e.data,
            number: number,
            date: date,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: state.admin.domainId,
            priceTotal: 0,
            status: "active",
        };
    }
    async function handleRowInsertedInitialState(e) {
        let initialStatePriceTotal = 0;
        const now = new Date();
        const allItemPromises = state.initialStateItemTableData.map((item) => {
            if (item.id) {
                delete item.id;
            }
            delete item.__KEY__;
            item.initialStateId = e.key;
            item.priceTotal = Number(item.priceTotal);
            item.createdById = state.admin.id;
            item.modifiedById = state.admin.id;
            item.createdDate = now.toISOString();
            item.modifiedDate = now.toISOString();
            item.status = "active";
            initialStatePriceTotal += item.priceTotal;
            return Promise.all([
                initialStateItem.insert(item),
                updateWarehouseItemStatusOnNewInitialState(
                    item,
                    e.data.warehouseId
                ),
            ]);
        });
        await Promise.all(allItemPromises);
        await initialState.update(e.key, {
            priceTotal: Number(initialStatePriceTotal),
        });
        state.initialStateItemTableData = [];
    }
    async function handleEditingStartInitialState(e) {
        state.editingInitialState = e.data;
        await initialStateItem.load();
    }
    function handleEditCanceledInitialState() {
        state.rowsToBeDeleted = [];
        state.newEditingInitialStateItemWarehouseItemStatus = [];
        state.editingInitialStateItemOldData = [];
        state.initialStateItemTableData = [];
    }
    async function handleRowUpdatingInitialState(e) {
        let initialStatePriceTotal = 0;
        const now = new Date().toISOString();

        const allPromises = [];

        const deletePromises = state.rowsToBeDeleted.map((item) => {
            initialStateItem
                .remove(item.id)
                .then(() => {
                    const loadOptions = {
                        priceListItemId: item.priceListItemId,
                        warehouseId: e.oldData.warehouseId,
                    };
                    return warehouseItemStatus.load(loadOptions);
                })
                .then((res) => {
                    if (res.length > 0) {
                        const currentQty = res[0].qty ?? 0;
                        const adjustedQty =
                            item.qty > 0
                                ? currentQty - Math.abs(item.qty)
                                : currentQty + Math.abs(item.qty);
                        return warehouseItemStatus.update(res[0].id, {
                            qty: adjustedQty,
                        });
                    }
                });
        });
        allPromises.push(...deletePromises);
        allPromises.push(
            updateWarehouseItemStatusOnUpdatedInitialState(
                state.newEditingInitialStateItemWarehouseItemStatus,
                state.editingInitialStateItemOldData,
                state.initialStateItemTableData,
                e.oldData.warehouseId
            )
        );

        const insertPromises = state.initialStateItemTableData.map((item) => {
            if (item.id) {
                allPromises.push(initialStateItem.remove(item.id));
                delete item.id;
            }
            if (item.__KEY__) delete item.__KEY__;

            Object.assign(item, {
                initialStateId: e.key,
                createdById: state.admin.id,
                modifiedById: state.admin.id,
                createdDate: now,
                modifiedDate: now,
                status: "active",
            });

            return Promise.all([
                initialStateItem.insert(item),
                priceListItem.update(item.priceListItemId, {
                    latestProcurementPrice: item.price,
                }),
            ]).then(() => {
                initialStatePriceTotal += item.priceTotal;
            });
        });
        allPromises.push(...insertPromises);
        await Promise.all(allPromises);
        state.rowsToBeDeleted = [];
        state.newEditingInitialStateItemWarehouseItemStatus = [];
        state.editingInitialStateItemOldData = [];

        await initialState.update(e.key, {
            priceTotal: Number(initialStatePriceTotal),
        });
    }
    async function handleRowRemovingInitialState(e) {
        await initialState.update(e.row.data.id, { status: "deleted" });
        const deletePromises = state.initialStateItemTableData.map((item) => {
            return Promise.all([
                initialStateItem.update(item.id, { status: "deleted" }),
                updateWarehouseItemStatusOnRemovedInitialState(
                    item,
                    e.row.data.warehouseId
                ),
            ]);
        });
        await Promise.all(deletePromises);
    }
    function handleCancelInitialStateItem() {
        state.editingInitialStateItemOldData.pop();
        state.editingInitialStateItem = null;
    }
    function handleSaveNewInitialStateItem(qty, price, priceTotal) {
        const now = new Date().toISOString();
        if (state.editingInitialStateItem !== null) {
            state.editingInitialStateItem.qty = qty;
            state.editingInitialStateItem.price = price;
            state.editingInitialStateItem.priceTotal = Number(priceTotal);
            state.editingInitialStateItem.modifiedDate = now;
            state.editingInitialStateItem.modifiedById = state.admin.id;
            state.newEditingInitialStateItemWarehouseItemStatus.push(
                state.editingInitialStateItem
            );
            state.initialStateItemTableData.find((el) => {
                if (
                    state.editingInitialStateItem.__KEY__ &&
                    el.__KEY__ === state.editingInitialStateItem.__KEY__
                ) {
                    el = state.editingInitialStateItem;
                } else if (el.id === state.editingInitialStateItem.id) {
                    el = state.editingInitialStateItem;
                }
            });
            state.editingInitialStateItem = null;
        } else {
            state.editingInitialStateItem = null;
        }
    }
    function handleRowInsertingInitialStateItem(
        e,
        qty,
        price,
        priceTotal,
        priceListItemId
    ) {
        e.data = {
            ...e.data,
            qty: qty,
            price: price,
            priceTotal: Number(priceTotal),
            priceListItemId: priceListItemId,
        };
    }
    function handleEditingStartInitialStateItem(e) {
        state.editingInitialStateItem = e.data;
        const oldData = {
            ...state.editingInitialStateItem,
        };
        state.editingInitialStateItemOldData.push(oldData);
    }
    return {
        ...toRefs(state),
        handleRowInsertingInitialState,
        handleRowInsertedInitialState,
        handleEditingStartInitialState,
        handleEditCanceledInitialState,
        handleRowUpdatingInitialState,
        handleRowRemovingInitialState,
        handleCancelInitialStateItem,
        handleSaveNewInitialStateItem,
        handleRowInsertingInitialStateItem,
        handleEditingStartInitialStateItem,
    };
}

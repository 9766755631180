<template>
    <div style="margin: 3vh auto; max-width: 500px; text-align: center">
        <img
            class="logo-img"
            src="@/assets/logo-minierp.svg"
            width="90"
            height="90"
        />
    </div>
    <div style="max-width: 50vw; margin: 3vh auto; text-align: center">
        <h2 v-if="errorMessage">
            {{ errorMessage }}
        </h2>
        <h2 v-if="successMessage">{{ successMessage }}</h2>
        <p v-if="successMessage">
            Bit ćete preusmjereni na prijavu za... {{ sec }}
        </p>
    </div>
</template>

<script>
export default {
    name: "activationStatus",
    props: ["error", "success"],
    data() {
        return {
            errorMessage: "",
            successMessage: "",
            sec: 5,
        };
    },

    created() {
        if (this.error) {
            this.handleError(this.error);
        }
        if (this.success) {
            this.handleSuccess();
        }
    },

    methods: {
        handleError(error) {
            if (error === "InvalidOrExpiredToken") {
                this.errorMessage =
                    "Aktivacijski token nije valjan ili je istekao.";
            } else if (error === "TokenExpired") {
                this.errorMessage = "Aktivacijski token je istekao.";
            } else {
                this.errorMessage = "Neočekivana pogreška.";
            }
        },

        handleSuccess() {
            let countdown = 5;
            this.countdownInterval = setInterval(() => {
                if (countdown >= 0) {
                    this.sec = countdown;
                    countdown--;
                } else {
                    clearInterval(this.countdownInterval);
                    this.$router.push("/login");
                }
            }, 1000);
            this.successMessage = "Domena uspješno aktivirana!";
        },
    },
};
</script>

import { watch } from "vue";
import router from "@/router";

export const userTrackingPlugin = (context) => {
    console.log("TESTING PLUGIN");

    const { store } = context;

    let isInitialChange = true;
    let isHandlingStateChange = false;

    if (store.$id === "domainUserStore") {
        store.$subscribe((mutation, state) => {
            if (isHandlingStateChange) return;

            if (isInitialChange) {
                return;
            }
            if (mutation.events.key === "user") {
                if (!state.user) {
                    isInitialChange = true;
                    isHandlingStateChange = true;
                    store
                        .logout()
                        .finally(
                            () => (
                                (isHandlingStateChange = false),
                                router.push({ name: "login" })
                            )
                        );
                } else {
                    notifySuperAdmin(state.user);
                    isHandlingStateChange = true;
                    store
                        .logout()
                        .finally(
                            () => (
                                (isHandlingStateChange = false),
                                router.push({ name: "login" })
                            )
                        );
                }
            }
        });
        watch(
            () => store.user,
            (newVal, oldVal) => {
                if (isHandlingStateChange) return;

                if (isInitialChange) {
                    isInitialChange = false;
                    return;
                }
                console.log(oldVal);
                if (newVal === null) {
                    isInitialChange = true;
                    isHandlingStateChange = true;
                    store.logout().finally(
                        () => (
                            (isHandlingStateChange = false),
                            router.push({
                                name: "login",
                                query: {
                                    expired:
                                        "Ručno mijenjanje podataka nije dozvoljeno. Obavijest je poslana super adminu.",
                                },
                            })
                        )
                    );
                } else {
                    notifySuperAdmin(newVal);
                    isHandlingStateChange = true;
                    store.logout().finally(
                        () => (
                            (isHandlingStateChange = false),
                            router.push({
                                name: "login",
                                query: {
                                    expired:
                                        "Ručno mijenjanje podataka nije dozvoljeno. Obavijest je poslana super adminu.",
                                },
                            })
                        )
                    );
                }
            },
            { deep: true }
        );
    }
};
function notifySuperAdmin(user) {
    //TODO: Napraviti implementaciju
    console.log("Šaljem obavijest super adminu.", user);
}

export default userTrackingPlugin;

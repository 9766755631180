<template>
    <div class="settings-container">
        <span class="table-name">Postavke</span>
        <span style="color: rgb(70, 120, 178)"
            >Ne zaboravite spremiti promjene prije nastavka korištenja
            aplikacije.</span
        >
        <DxTabPanel
            :animation-enabled="true"
            :focus-state-enabled="false"
            :show-nav-buttons="true"
        >
            <DxItem title="Osnovno" icon="info">
                <DxSelectBox
                    class="bussiness-year-select-box"
                    :data-source="businessYearData"
                    display-expr="year"
                    value-expr="id"
                    styling-mode="underlined"
                    label="Poslovna godina"
                    @initialized="getBusinessYearSelectBoxInstance"
                    @value-changed="onValueChangedBusinessYear"
                    :value="startValue"
                />
                <div class="button-container">
                    <NormalButton
                        class="save-button"
                        text="Spremi"
                        @click="saveNewBusinessYear"
                        type="success"
                    />
                </div>
                <DxSelectBox
                    class="bussiness-year-select-box"
                    :data-source="themesSelectionData"
                    display-expr="name"
                    value-expr="value"
                    styling-mode="underlined"
                    label="Tema"
                    @value-changed="onValueChangedThemeSelection"
                    :value="currentTheme"
                />
            </DxItem>
            <DxItem title="Email" icon="mention">
                <div v-if="adminRole === 'admin'">
                    <div class="html-editor-title">
                        <span>Zadani podaci za email (Ponuda)</span>
                    </div>
                    <DxTextBox
                        class="email-data"
                        width="500px"
                        label="Pošiljatelj"
                        placeholder="primjer@primjer.com"
                        styling-mode="underlined"
                        :value="offerEmailData.email_from"
                        @initialized="getOfferEmailFromInstance"
                    >
                        <DxValidator
                            @initialized="getOfferEmailValidatorInstance"
                            validationGroup="offerEmail"
                        >
                            <DxEmailRule />
                        </DxValidator>
                    </DxTextBox>

                    <DxTextBox
                        class="email-data"
                        width="500px"
                        label="Predmet"
                        placeholder="Ponuda br. {var}*"
                        styling-mode="underlined"
                        :value="offerEmailData.subject"
                        @initialized="getOfferSubjectInstance"
                    />
                    <DxTextArea
                        class="email-data"
                        height="250px"
                        width="500px"
                        label="Poruka"
                        styling-mode="underlined"
                        :value="offerEmailData.message"
                        @initialized="getOfferMessageInstance"
                    />
                    <div class="html-editor-title">
                        <NormalButton
                            class="header-button"
                            text="Spremi"
                            @click="saveDefaultEmailDataOffer"
                            type="success"
                        />
                        <NormalButton
                            class="header-button"
                            text="Obriši"
                            @click="clearDefaultEmailDataOffer"
                            type="danger"
                        />
                    </div>
                </div>
                <div v-if="adminRole === 'admin'">
                    <div class="html-editor-title">
                        <span>Zadani podaci za email (Račun)</span>
                    </div>
                    <DxTextBox
                        class="email-data"
                        width="500px"
                        label="Pošiljatelj"
                        placeholder="primjer@primjer.com"
                        styling-mode="underlined"
                        :value="invoiceEmailData.email_from"
                        @initialized="getInvoiceEmailFromInstance"
                    >
                        <DxValidator
                            @initialized="getInvoiceEmailValidatorInstance"
                            validationGroup="invoiceEmail"
                        >
                            <DxEmailRule />
                        </DxValidator>
                    </DxTextBox>
                    <DxTextBox
                        class="email-data"
                        width="500px"
                        label="Predmet"
                        placeholder="Račun br. {var}*"
                        styling-mode="underlined"
                        :value="invoiceEmailData.subject"
                        @initialized="getInvoiceSubjectInstance"
                    />
                    <DxTextArea
                        class="email-data"
                        height="250px"
                        width="500px"
                        label="Poruka"
                        styling-mode="underlined"
                        :value="invoiceEmailData.message"
                        @initialized="getInvoiceMessageInstance"
                    />
                    <div class="html-editor-title">
                        <NormalButton
                            class="header-button"
                            text="Spremi"
                            @click="saveDefaultEmailDataInvoice"
                            type="success"
                        />
                        <NormalButton
                            class="header-button"
                            text="Obriši"
                            @click="clearDefaultEmailDataInvoice"
                            type="danger"
                        />
                    </div>
                </div>

                <div class="html-editor-title">
                    <span>Email potpis</span>
                </div>
                <DxHtmlEditor
                    value-type="html"
                    :value="signature"
                    height="625px"
                    class="dx-html-editor"
                    @initialized="getHtmlEditorSignatureInstance"
                    @value-changed="onValueChangedHTMLEditorSignature"
                    :toolbar="htmlEditorToolbarItems"
                >
                    <DxMediaResizing :enabled="true" />
                    <DxImageUpload file-upload-mode="base64" />
                </DxHtmlEditor>
                <div class="html-editor-title">
                    <NormalButton
                        class="header-button"
                        text="Spremi"
                        @click="saveEmailSignature"
                        type="success"
                    />
                    <NormalButton
                        class="header-button"
                        text="Obriši"
                        @click="clearEmailSignature"
                        type="danger"
                    />
                </div>
            </DxItem>
            <DxItem title="Dokumenti" icon="file" v-if="adminRole === 'admin'">
                <span class="pdf-editor-title">PDF zaglavlje</span>
                <DxHtmlEditor
                    value-type="html"
                    :value="header"
                    height="625px"
                    class="dx-html-editor"
                    @initialized="getHtmlEditorHeaderInstance"
                    @value-changed="onValueChangedHTMLEditorHeader"
                    :toolbar="htmlEditorToolbarItems"
                >
                    <DxMediaResizing :enabled="true" />
                    <DxImageUpload file-upload-mode="base64" />
                </DxHtmlEditor>
                <div class="html-editor-title">
                    <NormalButton
                        class="header-button"
                        text="Spremi zaglavlje"
                        @click="saveHeader"
                        type="success"
                    />
                    <NormalButton
                        class="header-button"
                        text="Testiraj zaglavlje"
                        @click="testExportHeader"
                        type="default"
                    />
                </div>
                <span class="pdf-editor-title">PDF podnožje</span>
                <DxHtmlEditor
                    value-type="html"
                    :value="footer"
                    height="625px"
                    class="dx-html-editor"
                    @initialized="getHtmlEditorFooterInstance"
                    @value-changed="onValueChangedHTMLEditorFooter"
                    :toolbar="htmlEditorToolbarItems"
                >
                    <DxMediaResizing :enabled="true" />
                    <DxImageUpload file-upload-mode="base64" />
                </DxHtmlEditor>
                <div class="html-editor-title">
                    <NormalButton
                        class="header-button"
                        text="Spremi podnožje"
                        @click="saveFooter"
                        type="success"
                    />
                    <NormalButton
                        class="header-button"
                        text="Testiraj podnožje"
                        @click="testExportFooter"
                        type="default"
                    />
                </div>

                <div>
                    <p style="font-size: 10px; margin-bottom: 2vh">
                        *{var} označava postavljanje varijable ako želite
                        dinamičko postavljanje iz podataka. (Npr. Ponuda br.
                        30-31/2024 - 30 = ID korisnika, 31 = broj ponude, 2024 =
                        godina).
                    </p>
                </div>
            </DxItem>
            <DxItem title="Uloge" icon="user" v-if="adminRole === 'admin'">
                <DxTabPanel
                    :focus-state-enabled="false"
                    :active-state-enabled="true"
                    :show-nav-buttons="true"
                    tabs-position="left"
                    styling-mode="secondary"
                    item-template="list-item"
                    icon-position="top"
                    :items="roles"
                    @title-click="onTitleClickRole"
                >
                    <template #list-item="{ data, index }">
                        <DxTextBox
                            style="margin-top: 2vh"
                            class="email-data"
                            width="500px"
                            label="Naziv"
                            :value="data.title"
                            v-if="index === roles.length - 1"
                            @value-changed="valueChangedRoleName"
                        />
                        <NormalButton
                            text="Spremi"
                            @click="saveNewRole"
                            type="success"
                            style="margin: 2vh auto"
                            v-if="index === roles.length - 1"
                        />

                        <DxTabPanel
                            :focus-state-enabled="false"
                            :show-nav-buttons="true"
                            :animation-enabled="true"
                            tabs-position="top"
                            v-if="index !== roles.length - 1"
                        >
                            <DxItem
                                title="Naziv"
                                icon="rename"
                                v-if="adminRole === 'admin'"
                            >
                                <DxTextBox
                                    style="margin-top: 2vh"
                                    :disabled="data.title === 'admin'"
                                    class="email-data"
                                    width="500px"
                                    label="Naziv"
                                    :value="data.title"
                                    @value-changed="valueChangedRoleName" />

                                <NormalButton
                                    text="Spremi"
                                    @click="saveUpdatedRole"
                                    type="success"
                                    style="margin: 2vh auto"
                                    :disabled="data.title === 'admin'"
                            /></DxItem>
                            <DxItem
                                title="Dozvole"
                                icon="todo"
                                v-if="adminRole === 'admin'"
                            >
                                <div
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin-bottom: 2vh;
                                        margin-left: 2vw;
                                    "
                                >
                                    <EditForm
                                        :form-data="data.privileges"
                                        @initialized="getEditFormInstance"
                                        @field-data-changed="
                                            onSwitchDataChanged
                                        "
                                        :col-count="1"
                                        width="200"
                                        :disabled="data.title === 'admin'"
                                    >
                                        <DxSimpleItem
                                            data-field="1"
                                            editor-type="dxSwitch"
                                            :label="{
                                                text: 'Početno stanje',
                                                alignment: 'left',
                                            }"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />

                                        <DxSimpleItem
                                            data-field="2"
                                            :label="{
                                                text: 'Primka',
                                                alignment: 'left',
                                            }"
                                            editor-type="dxSwitch"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />

                                        <DxSimpleItem
                                            data-field="3"
                                            :label="{
                                                text: 'Otpremnica',
                                                alignment: 'left',
                                            }"
                                            editor-type="dxSwitch"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />
                                        <DxSimpleItem
                                            data-field="4"
                                            :label="{
                                                text: 'Međuskladišnica',
                                                alignment: 'left',
                                            }"
                                            editor-type="dxSwitch"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />
                                        <DxSimpleItem
                                            data-field="5"
                                            :label="{
                                                text: 'Skladište',
                                                alignment: 'left',
                                            }"
                                            editor-type="dxSwitch"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />
                                        <DxSimpleItem
                                            data-field="6"
                                            :label="{
                                                text: 'Cijenik',
                                                alignment: 'left',
                                            }"
                                            editor-type="dxSwitch"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />
                                        <DxSimpleItem
                                            data-field="7"
                                            :label="{
                                                text: 'Dobavljači',
                                                alignment: 'left',
                                            }"
                                            editor-type="dxSwitch"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />
                                        <DxSimpleItem
                                            data-field="8"
                                            :label="{
                                                text: 'Kupci',
                                                alignment: 'left',
                                            }"
                                            editor-type="dxSwitch"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />
                                        <DxSimpleItem
                                            data-field="9"
                                            :label="{
                                                text: 'Ponuda',
                                                alignment: 'left',
                                            }"
                                            editor-type="dxSwitch"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />
                                        <DxSimpleItem
                                            data-field="10"
                                            :label="{
                                                text: 'Račun',
                                                alignment: 'left',
                                            }"
                                            editor-type="dxSwitch"
                                            :editor-options="
                                                switchEditorOptions
                                            "
                                            :col-span="1"
                                        />
                                        <DxButtonItem
                                            :button-options="
                                                submitButtonOptions2
                                            "
                                            :col-span="3"
                                        />
                                    </EditForm>
                                </div>
                            </DxItem>
                            <DxItem
                                title="Članovi"
                                icon="user"
                                v-if="adminRole === 'admin'"
                            >
                                <div
                                    style="
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin-top: 1vh;
                                    "
                                >
                                    <DxDataGrid
                                        id="domainUserTable"
                                        width="500px"
                                        :data-source="roleMembers"
                                        :repaint-changes-only="true"
                                        :show-column-lines="true"
                                        :show-row-lines="true"
                                        :show-borders="true"
                                        :column-auto-width="true"
                                        :hover-state-enabled="true"
                                        :row-alternation-enabled="true"
                                        :allow-column-resizing="true"
                                        @initialized="
                                            getDomainUserTableInstance
                                        "
                                    >
                                        <DxSearchPanel :visible="true" />
                                        <DxHeaderFilter :visible="true" />
                                        <DxScrolling
                                            column-rendering-mode="virtual"
                                        />
                                        <DxPaging :page-size="20" />
                                        <DxPager
                                            :visible="true"
                                            display-mode="full"
                                            :show-info="true"
                                            :show-navigation-buttons="true"
                                            :show-page-size-selector="true"
                                            :allowed-page-sizes="[10, 20, 30]"
                                        />
                                        <DxColumn
                                            :width="80"
                                            data-field="id"
                                            data-type="number"
                                            caption="ID"
                                            :allow-editing="false"
                                            :visible="false"
                                        />
                                        <DxColumn
                                            data-field="username"
                                            data-type="string"
                                            caption="Ime i prezime"
                                            :allow-hiding="false"
                                        />
                                        <DxColumn
                                            data-field="status"
                                            data-type="string"
                                            caption="Status"
                                            :allow-hiding="false"
                                        />
                                        <DxColumn
                                            data-field="roleId"
                                            data-type="number"
                                            caption="roleId"
                                            :allow-editing="false"
                                            :visible="false"
                                        />
                                        <DxColumn
                                            data-field="domainId"
                                            data-type="number"
                                            caption="Domena"
                                            :visible="false"
                                            :allow-editing="false"
                                        >
                                            <DxLookup
                                                :data-source="domain"
                                                value-expr="id"
                                                display-expr="name"
                                        /></DxColumn>
                                        <DxColumn
                                            type="buttons"
                                            :auto-width="true"
                                            :fixed="true"
                                            :show-in-column-chooser="false"
                                        >
                                            <DxButton
                                                hint="Uredi ulogu"
                                                icon="rename"
                                                @click="openChangeRolePopup"
                                                :disabled="isCurrentUser"
                                                :visible="isActive"
                                            />
                                            <DxButton
                                                hint="Zaključaj"
                                                icon="lock"
                                                @click="setInactiveUser"
                                                :disabled="isCurrentUser"
                                                :visible="isActive"
                                            />
                                            <DxButton
                                                hint="Otključaj"
                                                icon="unlock"
                                                @click="setActiveUser"
                                                :disabled="isCurrentUser"
                                                :visible="isInactive"
                                            />
                                        </DxColumn>
                                    </DxDataGrid></div
                            ></DxItem>
                        </DxTabPanel>
                    </template>
                </DxTabPanel>
            </DxItem>
            <DxItem
                title="Novi korisnik"
                icon="add"
                v-if="adminRole === 'admin'"
            >
                <form
                    @submit.prevent="addNewUser"
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                        margin-bottom: 3vh;
                        margin-top: 3vh;
                    "
                >
                    <DxForm
                        :data-source="newUserData"
                        label-location="top"
                        label-mode="floating"
                        class="dxForm"
                        :col-count="6"
                        @field-data-changed="onFieldDataChanged"
                        @initialized="getDomainUserFormInstance"
                        validation-group="newUserGroup"
                    >
                        <DxSimpleItem
                            data-field="userUsername"
                            :required="true"
                            :label="{ text: 'Ime i prezime' }"
                            :editor-options="emailEditorOptions"
                            :col-span="6"
                        >
                            <DxCustomRule
                                :validation-callback="validateString"
                                message="Korisničko ime ne smije sadržavati posebne znakove."
                            />
                            <DxRequiredRule message="Obavezno polje." />
                        </DxSimpleItem>
                        <DxSimpleItem
                            data-field="userEmail"
                            :label="{ text: 'Email' }"
                            :required="true"
                            :editor-options="emailEditorOptions"
                            :col-span="6"
                        >
                            <DxEmailRule message="Email nije ispravan." />
                            <DxRequiredRule message="Obavezno polje." />
                        </DxSimpleItem>
                        <DxSimpleItem
                            data-field="userPassword"
                            :label="{ text: 'Lozinka' }"
                            :editor-options="passwordEditorOptions"
                            :col-span="6"
                        >
                            <DxRequiredRule message="Obavezno polje." />
                        </DxSimpleItem>
                        <DxSimpleItem
                            data-field="repeatPassword"
                            :label="{
                                text: 'Potvrdite lozinku',
                            }"
                            :editor-options="passwordEditorOptions"
                            :col-span="6"
                        >
                            <DxRequiredRule message="Obavezno polje." />
                            <DxCompareRule
                                :comparison-target="passwordCompare"
                                message="Lozinke nisu jednake."
                            />
                        </DxSimpleItem>
                        <DxSimpleItem
                            data-field="role"
                            :label="{
                                text: 'Uloga',
                            }"
                            :editor-options="newUserRoleEditorOptions"
                            :col-span="6"
                            editor-type="dxSelectBox"
                        >
                            <DxRequiredRule message="Obavezno polje." />
                        </DxSimpleItem>
                        <DxEmptyItem :col-span="6" />
                        <DxEmptyItem :col-span="6" />
                        <DxButtonItem
                            :button-options="submitButtonOptions"
                            :col-span="3"
                        />
                        <DxButtonItem
                            :button-options="clearButtonOptions"
                            :col-span="3"
                        />
                    </DxForm></form
            ></DxItem>
        </DxTabPanel>
        <EditPopup
            :resize-enabled="true"
            :drag-outside-boundary="true"
            title="Uređivanje uloge"
            :toolbar-items="editPopupToolbarItems"
            width="380"
            height="300"
            @initialized="getEditPopupInstance"
        >
            <EditForm
                :form-data="editingUser"
                @initialized="getEditFormInstance"
                :col-count="1"
            >
                <DxSimpleItem
                    data-field="roleId"
                    :label="{
                        text: 'Uloga',
                    }"
                    editor-type="dxSelectBox"
                    :editor-options="roleSelectionOptions"
                    :col-span="1"
                />
            </EditForm>
        </EditPopup>
    </div>
</template>
<script>
//DevExpress
import {
    DxPager,
    DxLookup,
    DxColumn,
    DxButton,
    DxPaging,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    DxHeaderFilter,
} from "devextreme-vue/data-grid";
import {
    DxHtmlEditor,
    DxImageUpload,
    DxMediaResizing,
} from "devextreme-vue/html-editor";
import "devextreme-vue/text-area";
import DxSelectBox from "devextreme-vue/select-box";
import DxTextBox from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import { DxButton as NormalButton } from "devextreme-vue/button";
import { DxPopup as EditPopup } from "devextreme-vue/popup";
import { DxForm as EditForm } from "devextreme-vue/form";
import "devextreme-vue/switch";
import {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxEmptyItem,
} from "devextreme-vue/form";
import notify from "devextreme/ui/notify";
import {
    DxEmailRule,
    DxValidator,
    DxCustomRule,
    DxCompareRule,
    DxRequiredRule,
} from "devextreme-vue/validator";
import DxTabPanel, { DxItem } from "devextreme-vue/tab-panel";
import ValidationEngine from "devextreme/ui/validation_engine";

//Stores
import { role } from "@/stores/roleStore";
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { businessYear } from "@/stores/businessYearStore";
import { rolePrivileges } from "@/stores/rolePrivilegesStore.js";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { emailSignatureDefault } from "@/stores/emailSignatureDefaultStore.js";
import themes from "devextreme/ui/themes";
// import { refreshTheme } from "devextreme/viz/themes";
import eventBus from "../../eventBus.js";
//Utils
import { ref, onMounted, reactive } from "vue";
import { jsPDF } from "jspdf";
import { divWrapper } from "@/utils/divWrapper";

export default {
    name: "settingsComponent",
    components: {
        DxPager,
        DxLookup,
        DxColumn,
        DxButton,
        DxPaging,
        DxDataGrid,
        DxScrolling,
        DxSearchPanel,
        DxHeaderFilter,
        DxHtmlEditor,
        DxImageUpload,
        DxMediaResizing,
        DxSelectBox,
        DxTextBox,
        DxTextArea,
        NormalButton,
        EditPopup,
        EditForm,
        DxForm,
        DxSimpleItem,
        DxButtonItem,
        DxEmptyItem,
        DxEmailRule,
        DxValidator,
        DxCustomRule,
        DxCompareRule,
        DxRequiredRule,
        DxTabPanel,
        DxItem,
    },
    setup() {
        const domainUserStore = useDomainUserStore();
        const domainUserData = ref(null);
        const emailSignatureDefaultData = ref(null);
        const domainData = ref(null);
        const admin = ref(null);
        const header = ref(null);
        const footer = ref(null);
        const signature = ref(null);

        const offerEmailData = ref({
            subject: null,
            message: null,
            email_from: null,
        });
        const invoiceEmailData = ref({
            subject: null,
            message: null,
            email_from: null,
        });
        const themesSelectionData = ref([
            { value: "generic.light", name: "Light" },
            { value: "generic.dark", name: "Dark" },
        ]);
        const currentTheme = ref(
            localStorage.getItem("dx-theme") || "generic.light"
        );

        //instances
        const editFormInstance = ref(null);
        const editPopupInstance = ref(null);
        // const selectBoxInstance = ref(null);
        const offerSubjectInstance = ref(null);
        const offerMessageInstance = ref(null);
        // const offerTextBoxInstance = ref(null);
        // const offerTextAreaInstance = ref(null);
        const roleSelectionInstance = ref(null);
        const domainUserFormInstance = ref(null);
        const offerEmailFromInstance = ref(null);
        // const invoiceTextBoxInstance = ref(null);
        const invoiceMessageInstance = ref(null);
        const invoiceSubjectInstance = ref(null);
        const domainUserTableInstance = ref(null);
        // const invoiceTextAreaInstance = ref(null);
        const invoiceEmailFromInstance = ref(null);
        const htmlEditorHeaderInstance = ref(null);
        const htmlEditorFooterInstance = ref(null);
        const newUserRoleEditorInstance = ref(null);
        const htmlEditorSignatureInstance = ref(null);
        const offerEmailValidatorInstance = ref(null);
        const invoiceEmailValidatorInstance = ref(null);
        const businessYearSelectBoxInstance = ref(null);

        //component options
        // const themesSelectionBoxOptions = ref({
        //     items: themesSelectionData,
        //     valueExpr: "value",
        //     displayExpr: "name",
        //     onSelectionChanged: (e) => {
        //         console.log(e);
        //     },
        // });
        const passwordEditorOptions = ref({
            height: "50px",
            mode: "password",
        });
        const newUserRoleEditorOptions = ref({
            dataSource: role,
            valueExpr: "id",
            displayExpr: "name",
            height: "50px",
            searchMode: "contains",
            onInitialized: (e) => {
                newUserRoleEditorInstance.value = e.component;
            },
        });
        const emailEditorOptions = ref({ height: "50px" });
        const submitButtonOptions = ref({
            text: "Dodaj korisnika",
            useSubmitBehavior: true,
            width: "100%",
            alignment: "left",
            type: "success",
        });
        const submitButtonOptions2 = ref({
            text: "Spremi",
            width: "100%",
            alignment: "left",
            type: "success",
            onClick: () => {
                saveNewUserPermissions();
            },
        });
        const roleSelectionOptions = ref({
            dataSource: role,
            valueExpr: "id",
            displayExpr: "name",
            searchMode: "contains",
            placeholder: "Odaberi ulogu",
            onInitialized: (e) => {
                roleSelectionInstance.value = e.component;
            },
        });

        const clearButtonOptions = ref({
            text: "Očisti",
            width: "100%",
            alignment: "left",
            type: "danger",
            onClick: () => {
                clearNewUser();
            },
        });
        const switchEditorOptions = ref({
            switchedOnText: "DA",
            switchedOffText: "NE",
        });

        //general data
        const roles = ref([]);

        const newValue = ref(null);
        const isDirty = ref(false);

        const startValue = ref(Number(localStorage.getItem("businessYearId")));
        const roleMembers = ref(null);
        const editingUser = ref(null);
        const selectedRole = ref(null);

        const changedRoleName = ref(null);
        const businessYearData = ref(null);
        // const selectedRoleRolePrivileges = ref(null);
        let newUserData = reactive({
            userEmail: null,
            userPassword: null,
            repeatPassword: null,
            userUsername: null,
            role: null,
        });
        const userPermissionsData = ref({
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            10: false,
        });

        //HTML editor toolbar items
        const htmlEditorToolbarItems = ref({
            items: [
                "undo",
                "redo",
                "separator",
                {
                    name: "size",
                    acceptedValues: [
                        "8pt",
                        "10pt",
                        "12pt",
                        "14pt",
                        "18pt",
                        "24pt",
                        "36pt",
                    ],
                },
                {
                    name: "font",
                    acceptedValues: [
                        "Arial",
                        "Georgia",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                    ],
                },
                "separator",
                "bold",
                "italic",
                "strike",
                "underline",
                "separator",
                "alignLeft",
                "alignCenter",
                "alignRight",
                "alignJustify",
                "separator",
                "orderedList",
                "bulletList",
                "separator",
                {
                    name: "header",
                    acceptedValues: [false, 1, 2, 3, 4, 5],
                },
                "separator",
                "color",
                "background",
                "separator",
                "link",
                "image",
                "separator",
                "clear",
                "codeBlock",
                "blockquote",
                "separator",
            ],
            multiline: true,
        });
        //edit popup toolbar items
        const editPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    width: 120,
                    useSubmitBehavior: true,
                    onClick: () => saveNewUserRole(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => cancelNewUserRole(),
                },
            },
        ]);
        onMounted(async () => {
            currentTheme.value =
                localStorage.getItem("dx-theme") || "generic.light";
            themes.current(currentTheme.value);
            admin.value = domainUserStore.getDomainUser;
            adminId.value = admin.value.id;
            adminRole.value = admin.value.role;
            adminDomainId.value = admin.value.domainId;
            domainUserData.value = await domainUser.byKey(admin.value.id);
            emailSignatureDefaultData.value = await emailSignatureDefault.load({
                domainId: domainUserData.value.domainId,
            });
            domainData.value = await domain.byKey(admin.value.domainId);
            businessYearData.value = await businessYear.load();
            if (domainUserData.value.emailSignature !== null) {
                signature.value = domainUserData.value.emailSignature;
            } else if (emailSignatureDefaultData.value[0].signature !== null) {
                signature.value = `--<br>
                <strong>${domainUser.value.username}</strong><br>
                ${emailSignatureDefault.value.signature}`;
            } else {
                signature.value = "Ovdje postavite svoj potpis za email.";
            }

            if (
                domainData.value.documentSections &&
                domainData.value.documentSections !== null
            ) {
                const sections = JSON.parse(domainData.value.documentSections);
                header.value = sections.header
                    ? sections.header
                    : "Ovdje postavite svoje zaglavlje za dokumente.";
                footer.value = sections.footer
                    ? sections.footer
                    : "Ovdje postavite svoje podnožje za dokumente.";
            } else {
                header.value = "Ovdje postavite svoje zaglavlje za dokumente.";
                footer.value = "Ovdje postavite svoje podnožje za dokumente.";
            }
            if (
                domainData.value.defaultEmailContent &&
                domainData.value.defaultEmailContent !== null
            ) {
                const defaultEmailContent = JSON.parse(
                    domainData.value.defaultEmailContent
                );
                if (defaultEmailContent.ponuda) {
                    offerEmailData.value = defaultEmailContent.ponuda;
                }
                if (defaultEmailContent.racun) {
                    invoiceEmailData.value = defaultEmailContent.racun;
                }
            }
            await loadRoleData();
        });
        const adminId = ref(null);
        const adminRole = ref(null);
        const adminDomainId = ref(null);

        //instance getters
        const getDomainUserTableInstance = (e) => {
            domainUserTableInstance.value = e.component;
        };
        const getEditPopupInstance = (e) => {
            editPopupInstance.value = e.component;
        };
        const getEditFormInstance = (e) => {
            editFormInstance.value = e.component;
        };
        const getDomainUserFormInstance = (e) => {
            domainUserFormInstance.value = e.component;
        };
        const getBusinessYearSelectBoxInstance = (e) => {
            businessYearSelectBoxInstance.value = e.component;
            businessYearSelectBoxInstance.value.option(
                "value",
                startValue.value
            );
        };
        const getHtmlEditorSignatureInstance = (e) => {
            htmlEditorSignatureInstance.value = e.component;
            htmlEditorSignatureInstance.value.option("value", signature.value);
        };
        const getHtmlEditorHeaderInstance = (e) => {
            htmlEditorHeaderInstance.value = e.component;
            htmlEditorHeaderInstance.value.option("value", header.value);
        };
        const getHtmlEditorFooterInstance = (e) => {
            htmlEditorFooterInstance.value = e.component;
        };
        const getInvoiceEmailValidatorInstance = (e) => {
            invoiceEmailValidatorInstance.value = e.component;
        };
        const getOfferEmailValidatorInstance = (e) => {
            offerEmailValidatorInstance.value = e.component;
        };
        const getOfferEmailFromInstance = (e) => {
            offerEmailFromInstance.value = e.component;
            offerEmailFromInstance.value.option(
                "value",
                offerEmailData.value.email_from
            );
        };
        const getOfferSubjectInstance = (e) => {
            offerSubjectInstance.value = e.component;
            offerSubjectInstance.value.option(
                "value",
                offerEmailData.value.subject
            );
        };
        const getOfferMessageInstance = (e) => {
            offerMessageInstance.value = e.component;
            offerMessageInstance.value.option(
                "value",
                offerEmailData.value.message
            );
        };
        const getInvoiceEmailFromInstance = (e) => {
            invoiceEmailFromInstance.value = e.component;
            invoiceEmailFromInstance.value.option(
                "value",
                invoiceEmailData.value.email_from
            );
        };
        const getInvoiceSubjectInstance = (e) => {
            invoiceSubjectInstance.value = e.component;
            invoiceSubjectInstance.value.option(
                "value",
                invoiceEmailData.value.subject
            );
        };
        const getInvoiceMessageInstance = (e) => {
            invoiceMessageInstance.value = e.component;
            invoiceMessageInstance.value.option(
                "value",
                invoiceEmailData.value.message
            );
        };

        //methods
        const onValueChangedBusinessYear = (e) => {
            newValue.value = e.value;
        };
        const onValueChangedHTMLEditorSignature = (e) => {
            if (signature.value !== e.value) {
                isDirty.value = true;
            } else {
                isDirty.value = false;
            }
        };
        const onValueChangedHTMLEditorHeader = (e) => {
            if (header.value !== e.value) {
                isDirty.value = true;
            } else {
                isDirty.value = false;
            }
        };
        const onValueChangedHTMLEditorFooter = (e) => {
            if (footer.value !== e.value) {
                isDirty.value = true;
            } else {
                isDirty.value = false;
            }
        };
        const onValueChangedThemeSelection = (e) => {
            themes.current(e.value);
            currentTheme.value = e.value;
            localStorage.setItem("dx-theme", e.value);
            // refreshTheme();
            document.documentElement.setAttribute(
                "data-theme",
                currentTheme.value
            );
            eventBus.emit("themeChanged", currentTheme.value);
        };

        //button methods
        const saveDefaultEmailDataOffer = () => {
            const isValid =
                offerEmailValidatorInstance.value.validate("offerEmail");
            if (isValid.isValid) {
                offerEmailData.value = {
                    email_from: offerEmailFromInstance.value.option("value"),
                    subject: offerSubjectInstance.value.option("value"),
                    message: offerMessageInstance.value.option("value"),
                };

                invoiceEmailData.value = {
                    email_from: invoiceEmailFromInstance.value.option("value"),
                    subject: invoiceSubjectInstance.value.option("value"),
                    message: invoiceMessageInstance.value.option("value"),
                };
                const defaultEmailContent = {
                    ponuda: offerEmailData.value,
                    racun: invoiceEmailData.value,
                };
                const defaultEmailContentString =
                    JSON.stringify(defaultEmailContent);
                domain.update(adminDomainId.value, {
                    defaultEmailContent: defaultEmailContentString,
                });
                const message = "Podaci spremljeni.";
                notify({ message, width: 450 }, "success");
            }
        };
        const clearDefaultEmailDataOffer = () => {
            offerEmailData.value = {
                email_from: null,
                subject: null,
                message: null,
            };
            offerEmailFromInstance.value.option("value", null);
            offerSubjectInstance.value.option("value", null);
            offerMessageInstance.value.option("value", null);
            const message = "Podaci obrisani.";
            notify({ message, width: 450 }, "success");
        };
        const saveDefaultEmailDataInvoice = () => {
            const isValid =
                invoiceEmailValidatorInstance.value.validate("offerEmail");
            if (isValid.isValid) {
                offerEmailData.value = {
                    email_from: offerEmailFromInstance.value.option("value"),
                    subject: offerSubjectInstance.value.option("value"),
                    message: offerMessageInstance.value.option("value"),
                };

                invoiceEmailData.value = {
                    email_from: invoiceEmailFromInstance.value.option("value"),
                    subject: invoiceSubjectInstance.value.option("value"),
                    message: invoiceMessageInstance.value.option("value"),
                };
                const defaultEmailContent = {
                    ponuda: offerEmailData.value,
                    racun: invoiceEmailData.value,
                };
                const defaultEmailContentString =
                    JSON.stringify(defaultEmailContent);
                domain.value.update(adminDomainId.value, {
                    defaultEmailContent: defaultEmailContentString,
                });
                const message = "Podaci spremljeni.";
                notify({ message, width: 450 }, "success");
            }
        };
        const clearDefaultEmailDataInvoice = () => {
            invoiceEmailData.value = {
                email_from: null,
                subject: null,
                message: null,
            };
            invoiceEmailFromInstance.value.option("value", null);
            invoiceSubjectInstance.value.option("value", null);
            invoiceMessageInstance.value.option("value", null);
            const message = "Podaci obrisani.";
            notify({ message, width: 450 }, "success");
        };
        const saveFooter = async () => {
            header.value = htmlEditorHeaderInstance.value.option("value");
            header.value = header.value.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text.replace(/ /g, " ").replace(/\t/g, "    ") +
                        closeTag
                    );
                }
            );
            footer.value = htmlEditorFooterInstance.value.option("value");
            footer.value = footer.value.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text.replace(/ /g, " ").replace(/\t/g, "    ") +
                        closeTag
                    );
                }
            );
            const documentSections = {
                header: header.value,
                footer: footer.value,
            };
            const documentSectionsString = JSON.stringify(documentSections);
            const updateData = {
                documentSections: documentSectionsString,
            };
            await domain.update(adminDomainId.value, updateData);
            const message = "Podaci spremljeni.";
            notify({ message, width: 450 }, "success");
        };
        const testExportFooter = () => {
            const doc = new jsPDF();
            let htmlContent = htmlEditorFooterInstance.value.option("value");
            htmlContent = htmlContent.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text.replace(/ /g, " ").replace(/\t/g, "    ") +
                        closeTag
                    );
                }
            );
            htmlContent = divWrapper(htmlContent);

            const pageHeight = doc.internal.pageSize.getHeight();
            doc.html(htmlContent, {
                callback: () => {
                    doc.save("test-footer.pdf");
                },
                x: 15,
                y: pageHeight - 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 794,
            });
        };
        const saveHeader = async () => {
            header.value = htmlEditorHeaderInstance.value.option("value");
            header.value = header.value.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text.replace(/ /g, " ").replace(/\t/g, "    ") +
                        closeTag
                    );
                }
            );
            footer.value = htmlEditorFooterInstance.value.option("value");
            footer.value = footer.value.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text.replace(/ /g, " ").replace(/\t/g, "    ") +
                        closeTag
                    );
                }
            );
            const documentSections = {
                header: header.value,
                footer: footer.value,
            };
            const documentSectionsString = JSON.stringify(documentSections);
            const updateData = {
                documentSections: documentSectionsString,
            };
            await domain.update(adminDomainId.value, updateData);
            const message = "Podaci spremljeni.";
            notify({ message, width: 450 }, "success");
        };
        const testExportHeader = () => {
            const doc = new jsPDF();
            let htmlContent = htmlEditorHeaderInstance.value.option("value");
            htmlContent = htmlContent.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text.replace(/ /g, " ").replace(/\t/g, "    ") +
                        closeTag
                    );
                }
            );
            htmlContent = divWrapper(htmlContent);
            doc.html(htmlContent, {
                callback: () => {
                    // doc.line(15, 130, 580, 130);
                    doc.setFontSize(10);
                    doc.line(15, 60, 195, 60);
                    doc.text("Pocetak dokumenta", 15, 65);
                    doc.save("test-header.pdf");
                },
                x: 15,
                y: 15,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 794,
            });
        };
        const saveEmailSignature = async () => {
            signature.value = htmlEditorSignatureInstance.value.option("value");
            domainUser.update(adminId.value, {
                emailSignature: signature.value,
            });
            const message = "Podaci spremljeni.";
            notify({ message, width: 450 }, "success");
        };
        const clearEmailSignature = () => {
            htmlEditorSignatureInstance.value.option("value", null);
            signature.value = null;
            const message = "Podaci obrisani.";
            notify({ message, width: 450 }, "success");
        };
        const saveNewBusinessYear = () => {
            if (newValue.value) {
                localStorage.setItem("businessYearId", newValue.value);
                const message = "Postavke spremljene.";
                notify({ message, width: 450 }, "success");
            }
            isDirty.value = false;
        };
        const saveNewUserPermissions = async () => {
            const rolePermissions = roles.value.filter((item) => {
                return item.id === selectedRole.value.id;
            });
            const rolePrivilegesRes = await rolePrivileges.load({
                roleId: selectedRole.value.id,
            });
            const filteredPermissions = Object.keys(
                rolePermissions[0].privileges
            )
                .filter((key) => rolePermissions[0].privileges[key] !== false)
                .map(Number);
            if (filteredPermissions.length > 0) {
                const res = await rolePrivileges.update(
                    rolePrivilegesRes[0].id,
                    {
                        privilegeId: JSON.stringify(filteredPermissions),
                    }
                );
                if (res) {
                    const message = "Postavke spremljene.";
                    notify({ message, width: 450 }, "success");
                }
            } else {
                const res = await rolePrivileges.update(
                    rolePrivilegesRes[0].id,
                    {
                        privilegeId: null,
                    }
                );
                if (res) {
                    const message = "Postavke spremljene.";
                    notify({ message, width: 450 }, "success");
                }
            }
        };
        const setInactiveUser = async (e) => {
            await domainUser.update(e.row.data.id, {
                status: "inactive",
            });
            roleMembers.value = await domainUser.load({
                roleId: selectedRole.value.id,
            });
        };
        const setActiveUser = async (e) => {
            await domainUser.update(e.row.data.id, {
                status: "active",
            });
            roleMembers.value = await domainUser.load({
                roleId: selectedRole.value.id,
            });
        };
        const openChangeRolePopup = (e) => {
            editingUser.value = e.row.data;
            editPopupInstance.value.show();
        };
        const saveNewUserRole = async () => {
            await domainUser.update(editingUser.value.id, {
                roleId: roleSelectionInstance.value.option("value"),
            });
            roleMembers.value = await domainUser.load({
                roleId: selectedRole.value.id,
            });
            editPopupInstance.value.hide();
        };
        const cancelNewUserRole = () => {
            editingUser.value = null;
            editPopupInstance.value.hide();
        };
        const onFieldDataChanged = (e) => {
            newUserData[e.dataField] = e.value;
        };
        const onSwitchDataChanged = (e) => {
            userPermissionsData.value[e.dataField] = e.value;
        };
        const loadRoleData = async () => {
            const fetchedRoles = await role.load({
                domainId: adminDomainId.value,
            });
            const adjustedArray = fetchedRoles.map((item) => ({
                id: item.id,
                title: item.name,
                icon: "group",
                privileges: {
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false,
                    8: false,
                    9: false,
                    10: false,
                },
            }));
            adjustedArray.push({
                id: adjustedArray.length + 1,
                title: "Nova",
                icon: "add",
                privileges: {},
            });

            roles.value = adjustedArray;
            selectedRole.value = roles.value[0];
            roleMembers.value = await domainUser.load({
                roleId: selectedRole.value.id,
            });

            for (const role of adjustedArray.slice(0, -1)) {
                const res = await rolePrivileges.load({
                    roleId: role.id,
                });
                if (res.length > 0 && res[0].privilegeId !== null) {
                    const userPrivileges = JSON.parse(res[0].privilegeId);
                    userPrivileges.forEach((key) => {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                role.privileges,
                                key
                            )
                        ) {
                            role.privileges[key] = true;
                        }
                    });
                }
            }
        };
        const valueChangedRoleName = (e) => {
            changedRoleName.value = e.value;
        };
        const saveNewRole = async () => {
            const now = new Date().toISOString();
            const newData = {
                name:
                    changedRoleName.value !== null
                        ? changedRoleName.value
                        : selectedRole.value.title,
                domainId: adminDomainId.value,
                createdDate: now,
                createdById: adminId.value,
                modifiedDate: now,
                modifiedById: adminId.value,
            };
            const res = await role.insert(newData);
            const newPrivileges = {
                roleId: res.id,
                privilegeId: null,
                createdDate: now,
                createdById: adminId.value,
                modifiedDate: now,
                modifiedById: adminId.value,
            };
            await rolePrivileges.insert(newPrivileges);
            await loadRoleData();
            const message = "Postavke spremljene.";
            notify({ message, width: 450 }, "success");
        };
        const saveUpdatedRole = async () => {
            const now = new Date().toISOString();
            await role.update(selectedRole.value.id, {
                name: changedRoleName.value,
                modifiedDate: now,
                modifiedById: adminId.value,
            });
            await loadRoleData();
            const message = "Postavke spremljene.";
            notify({ message, width: 450 }, "success");
        };
        const onTitleClickRole = async (e) => {
            selectedRole.value = e.itemData;
            roleMembers.value = await domainUser.load({
                roleId: selectedRole.value.id,
            });
        };
        const checkString = (str) => {
            const pattern = /^[A-Za-z0-9 čćđšžČĆĐŠŽ]*$/;
            return pattern.test(str);
        };

        const validateString = (e) => {
            return checkString(e.value);
        };
        const passwordCompare = () => {
            return newUserData.userPassword;
        };
        const isCurrentUser = (e) => {
            return e.row.data.id === adminId.value ? true : false;
        };
        const isInactive = (e) => {
            return e.row.data.status === "inactive" ? true : false;
        };
        const isActive = (e) => {
            return e.row.data.status === "active" ? true : false;
        };
        const addNewUser = async () => {
            delete newUserData.repeatPassword;
            newUserData = {
                ...newUserData,
                domainId: adminDomainId.value,
                roleId: newUserRoleEditorInstance.value.option("value"),
            };
            const res = await domainUserStore.registerNewDomainUser(
                newUserData
            );
            if (res.status === 200) {
                const message = "Korisnik uspješno dodan.";
                notify({ message, width: 450 }, "success");
                clearNewUser();
            }
        };
        const clearNewUser = () => {
            ValidationEngine.resetGroup("newUserGroup");
            domainUserFormInstance.value.clear();
        };
        return {
            role,
            domain,
            domainUser,
            businessYear,
            rolePrivileges,
            passwordEditorOptions,
            newUserRoleEditorOptions,
            emailEditorOptions,
            submitButtonOptions,
            submitButtonOptions2,
            roleSelectionOptions,
            clearButtonOptions,
            switchEditorOptions,
            roles,
            adminRole,
            htmlEditorToolbarItems,
            editPopupToolbarItems,
            getDomainUserTableInstance,
            getDomainUserFormInstance,
            getEditPopupInstance,
            getEditFormInstance,
            getBusinessYearSelectBoxInstance,
            getHtmlEditorSignatureInstance,
            getHtmlEditorHeaderInstance,
            getHtmlEditorFooterInstance,
            getInvoiceEmailValidatorInstance,
            getOfferEmailValidatorInstance,
            getOfferEmailFromInstance,
            getOfferSubjectInstance,
            getOfferMessageInstance,
            getInvoiceEmailFromInstance,
            getInvoiceSubjectInstance,
            getInvoiceMessageInstance,
            onValueChangedBusinessYear,
            onValueChangedHTMLEditorSignature,
            onValueChangedHTMLEditorHeader,
            onValueChangedHTMLEditorFooter,
            saveDefaultEmailDataOffer,
            clearDefaultEmailDataOffer,
            saveDefaultEmailDataInvoice,
            clearDefaultEmailDataInvoice,
            saveFooter,
            testExportFooter,
            saveHeader,
            testExportHeader,
            saveEmailSignature,
            clearEmailSignature,
            saveNewBusinessYear,
            setInactiveUser,
            setActiveUser,
            openChangeRolePopup,
            onFieldDataChanged,
            onSwitchDataChanged,
            valueChangedRoleName,
            saveNewRole,
            saveUpdatedRole,
            onTitleClickRole,
            validateString,
            passwordCompare,
            isCurrentUser,
            isInactive,
            isActive,
            addNewUser,
            offerEmailData,
            invoiceEmailData,
            signature,
            startValue,
            editingUser,
            businessYearData,
            header,
            footer,
            newUserData,
            roleMembers,
            themesSelectionData,
            onValueChangedThemeSelection,
            currentTheme,
        };
    },
};
</script>
<style scoped>
.settings-container {
    margin: 2vh;
    max-width: 100%;
    width: 85vw;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}
#warehouseTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    /* text-align: center; */
    margin-bottom: 2vh;
}
.dx-html-editor {
    margin: auto;
    width: 75%;
    text-align: left;
}
.html-editor-title {
    display: flex;
    width: 500px;
    margin: 2vh auto;
    align-items: flex-start;
    justify-content: space-around;
}
.pdf-editor-title {
    margin: 2vh;
    display: block;
}
.bussiness-year-select-box {
    width: 25%;
    margin: 2vh auto;
}
.save-button {
    width: 40%;
    margin: 25px;
}
.cancel-button {
    width: 40%;
    margin: 25px;
}
.header-button {
    width: 45%;
}
.button-container {
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.email-data {
    margin: auto;
}
.new-user-input {
    margin: 1vh auto;
}
@media screen and (max-width: 1024px) {
    .html-editor-title {
        width: 100%;
    }
    .email-data {
        width: 100%;
    }
    .settings-container {
        width: 100%;
    }
    .dx-html-editor {
        width: 100%;
    }
    .bussiness-year-select-box {
        width: 75%;
    }
    .button-container {
        width: 100%;
    }
}
</style>

<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid"
        id="initialStateItemTable"
        :data-source="initialStateItem"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :allow-column-resizing="true"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        :column-min-width="70"
        :column-auto-width="true"
        @initialized="getInitialStateItemTableInstance"
    >
        <DxToolbar>
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="initialStateItemTableState"
        />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPager
            :visible="true"
            display-mode="full"
            :show-info="true"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxColumnFixing :enabled="true" />
        <DxColumn data-field="id" data-type="number" :visible="false" />
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Stavka"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            width="120"
            data-field="price"
            data-type="number"
            caption="Cijena"
            format="###,###,###,##0.00"
        />
        <DxColumn
            width="120"
            data-field="qty"
            data-type="number"
            caption="Količina"
            format="###,###,###,##0.00"
        />
        <DxColumn
            width="120"
            data-field="priceTotal"
            data-type="number"
            format="###,###,###,##0.00"
            caption="Ukupno"
        />
        <DxSummary>
            <DxTotalItem
                column="priceTotal"
                summary-type="sum"
                :value-format="priceTotalFormat"
                display-format="{0}"
            />
        </DxSummary>
    </DxDataGrid>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxToolbar,
    DxItem,
    DxSearchPanel,
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLookup,
    DxStateStoring,
} from "devextreme-vue/data-grid";

import { useInitialStateItem } from "@/composables/useInitialStateItem.js";

//Utils
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted } from "vue";

export default {
    name: "initialStateItemTableComponent",
    components: {
        DxPager,
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxSummary,
        DxTotalItem,
        DxLookup,
        DxStateStoring,
        DxToolbar,
        DxItem,
        DxSearchPanel,
    },
    setup() {
        const {
            priceListItem,
            initialStateItem,
            priceTotalFormat,
            handleExportInitialState,
            getDomainData,
            initialStateData,
            fetchInitialStateDataByKey,
            fetchWarehouseDataByKey,
            fetchBusinessYearDataByKey,
        } = useInitialStateItem();
        onMounted(async () => {
            await getDomainData();
            await fetchBusinessYearDataByKey();
        });
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);

        const getInitialStateItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };

        eventBus.on("exportInitialState", (initialStateId) => {
            handleExportInitialState(tableInstance.value, initialStateId);
        });
        eventBus.on("newInitialStateItemAdded", () => {
            tableInstance.value.refresh();
        });
        eventBus.on("newFocusedRowInitialState", async (data) => {
            if (data && data !== null) {
                await fetchInitialStateDataByKey(data);
                await fetchWarehouseDataByKey(
                    initialStateData.value.warehouseId
                );
                focusedId.value = true;
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });

        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );

        return {
            getInitialStateItemTableInstance,
            priceListItem,
            initialStateItem,
            priceTotalFormat,
            shouldShowTheDataGrid,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#initialStateItemTable {
    width: 100%;
}
</style>

import { reactive, toRefs } from "vue";
import { domainUser } from "@/stores/domainUserStore";
import { priceList } from "@/stores/priceListStore";
import { domain } from "@/stores/domainStore";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { tax } from "@/stores/taxStore";
export function usePriceList() {
    const domainUserStore = useDomainUserStore();
    const state = reactive({
        tax,
        domain,
        domainUser,
        priceList,
        columnChooserMode: "select",
        admin: domainUserStore.getDomainUser,
    });
    function handleRowInitPriceList(e) {
        const now = new Date();
        e.data = {
            ...e.data,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: state.admin.domainId,
        };
    }

    function handleRowUpdatingPriceList(e) {
        const now = new Date();
        e.newData = {
            ...e.newData,
            modifiedById: state.admin.id,
            modifiedDate: now.toISOString(),
        };
    }

    return {
        ...toRefs(state),
        handleRowInitPriceList,
        handleRowUpdatingPriceList,
    };
}

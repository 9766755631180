import router from "@/router";
import config from "../../config.json";
export const sendRequest = async (url, method = "GET", data = {}) => {
    const otherMethods = ["POST", "PATCH"];
    const request = {};

    request.method = method;
    if (otherMethods.includes(method)) {
        request.headers = {
            "Content-Type": "application/json",
        };
        request.body = JSON.stringify(data.values);
    }
    request.credentials = "include";
    const response = await fetch(url, request);

    let result;
    if (response.status === 200) {
        result = await response.json();
        return result;
    }
    if (response.status === 401) {
        request.method = "POST";
        result = await response.json();
        await fetch(
            `${config.API_URL}/${config.PATHS.USERS}/${config.PATHS.LOGOUT}`,
            request
        );
        router.push({ name: "login", query: { expired: true } });
        return result;
    }
    if (response.status === 403) {
        request.method = "POST";
        result = await response.json();
        await fetch(
            `${config.API_URL}/${config.PATHS.USERS}/${config.PATHS.LOGOUT}`,
            request
        );
        router.push({
            name: "login",
            query: { expired: result.error.message },
        });
        return result;
    }
    if (!response.ok) {
        result = await response.json();
        throw new Error(result.error.message);
    }
};

import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { initialStateItem } from "@/stores/initialStateItemStore";
import { initialState } from "@/stores/initialStateStore";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { divWrapper } from "@/utils/divWrapper";
import { businessYear } from "@/stores/businessYearStore";
import { warehouse } from "@/stores/warehouseStore";
import { useDomainUserStore } from "@/stores/customDomainUserStore";

export function useInitialStateItem() {
    const domainUserStore = useDomainUserStore();
    const state = reactive({
        priceListItem,
        initialStateItem,
        documentHeader: null,
        documentFooter: null,
        businessYearName: null,
        domainName: null,
        initialStateData: null,
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2,
        },
        admin: domainUserStore.getDomainUser,
        businessYearId: localStorage.getItem("businessYearId"),
    });
    async function fetchInitialStateDataByKey(id) {
        try {
            state.initialStateData = await initialState.byKey(id);
        } catch (error) {
            console.error("Error fetching warehouse data by key:", error);
            throw error;
        }
    }
    async function fetchWarehouseDataByKey(id) {
        try {
            const res = await warehouse.byKey(id);
            state.warehouseName = res.name;
        } catch (error) {
            console.error("Error fetching warehouse data by key:", error);
            throw error;
        }
    }
    async function fetchBusinessYearDataByKey() {
        try {
            const res = await businessYear.byKey(state.businessYearId);
            state.businessYearName = res.year;
        } catch (error) {
            console.error("Error fetching business year data by key:", error);
            throw error;
        }
    }

    async function getDomainData() {
        const domainData = await domain.byKey(state.admin.domainId);
        state.domainName = domainData.name;
        if (
            domainData.documentSections &&
            domainData.documentSections !== null
        ) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    async function handleExportInitialState(table, initialStateId) {
        if (state.initialStateData.id !== initialStateId) {
            await fetchInitialStateDataByKey(initialStateId);
            await fetchWarehouseDataByKey(state.initialStateData.warehouseId);
        }
        const doc = new jsPDF();
        doc.setFontSize(8);
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            topLeft: { x: 1, y: 40 },
            repeatHeaders: true,
            onRowExporting: (e) => {
                e.rowHeight = 6;
            },
            columnWidths: [100, 26, 27, 27],
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }

                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 7;
                }
            },
        }).then(() => {
            const now = new Date();
            const vrijeme = "Vrijeme izrade: " + now.toLocaleString("hr-HR");
            const header = `Početno stanje ${state.businessYearName}`;
            const subHeader = `Skladište: ${state.warehouseName}`;
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageCount = doc.internal.getNumberOfPages();
            doc.setFontSize(8);
            doc.setPage(1);
            doc.text(vrijeme, pageWidth - 15, 50, { align: "right" });
            doc.setFontSize(12);
            doc.text(header, 15, 45);
            doc.setFontSize(8);
            doc.text(subHeader, 15, 50);
            doc.line(15, 53, pageWidth - 15, 53);
            if (lastPoint.y + 35 > pageHeight) {
                doc.addPage();
                doc.setPage(pageCount + 1);
                doc.text("Zaprimio/la:", pageWidth - 50, 15, {
                    align: "center",
                });
                doc.text(
                    state.admin.username,
                    pageWidth - 50,
                    lastPoint.y + 30,
                    {
                        align: "center",
                    }
                );
                doc.line(pageWidth - 85, 30, pageWidth - 15, 30);
            } else {
                doc.text("Zaprimio/la:", pageWidth - 50, lastPoint.y + 10, {
                    align: "center",
                });
                doc.text(
                    state.admin.username,
                    pageWidth - 50,
                    lastPoint.y + 30,
                    {
                        align: "center",
                    }
                );
                doc.line(
                    pageWidth - 85,
                    lastPoint.y + 25,
                    pageWidth - 15,
                    lastPoint.y + 25
                );
            }
            const documentName = `PočetnoStanje-${state.domainName}-${state.businessYearName}.pdf`;
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 800,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            doc.save(documentName);
                        },
                    });
                },
            });
        });
    }
    return {
        ...toRefs(state),
        getDomainData,
        fetchInitialStateDataByKey,
        fetchWarehouseDataByKey,
        fetchBusinessYearDataByKey,
        handleExportInitialState,
    };
}

import { createApp } from "vue";
import { createPinia } from "pinia";
import userTrackingPlugin from "./plugins/userTrackingPlugin.js";
import App from "./App.vue";
// import "devextreme/dist/css/dx.light.css";
import router from "./router";
import config from "devextreme/core/config";
import { locale } from "devextreme/localization";

const pinia = createPinia();
pinia.use(userTrackingPlugin);
config({
    // decimalSeparator: ',', //deprecated
    // thousandsSeparator: '.', //deprecated
    defaultCurrency: "EUR",
    serverDecimalSeparator: ".",
    thousandsSeparator: "",
    forceIsoDateParsing: true,
    editorStylingMode: "filled", // 'outlined' | 'underlined' | 'filled'

    // floatingActionButtonConfig: directions[e.selectedItem]
});

locale("hr-HR");

createApp(App).use(router).use(pinia).mount("#app");

import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let loadOptions = null;
eventBus.on("calculateWarehouseItemStatus", (data) => {
    loadOptions = {
        businessYearId: data.businessYearId,
        warehouseId: data.warehouseId,
        domainId: data.domainId,
        createdDate: data.createdDate,
    };
});
export const warehouseItemStatusCalculation = new CustomStore({
    key: "id",
    load: async () => {
        try {
            if (loadOptions !== null) {
                let filterCondition = {
                    warehouseId: loadOptions.warehouseId,
                    businessYearId: loadOptions.businessYearId,
                    domainId: loadOptions.domainId,
                    createdDate: loadOptions.createdDate,
                };
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.CALCULATE_WAREHOUSE_STATUS
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                loadOptions = null;
                return newData;
            } else {
                return [];
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    update: async (values) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.CALCULATE_CURRENT_WAREHOUSE_STATUS}`,
                "PATCH",
                {
                    values: values,
                }
            );
            return res;
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
});
